import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "api/users/users";
import { UpdateUserObject } from "interfaces/users/users";

export const useUpdateUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((user: UpdateUserObject) => updateUser(user), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "User was updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Call the refetch function passed in the context
      context?.refetch();
      queryClient.invalidateQueries(["users"]);
    },
    onError: () => {
      toast({
        title: "User failed to update!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
