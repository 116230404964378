import Card from "components/card";
import { useAddTerms } from "hooks/terms/useAddTerms";
import { Terms } from "interfaces/globals/globals";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  data: Terms;
}

const TermsEditor: React.FC<Props> = ({ data }) => {
  const { mutate } = useAddTerms();
  const [description, setDescription] = useState(data?.description);

  useEffect(() => {
    setDescription(data?.description);
  }, [data]);

  const handleDescriptionChange = (content: string) => {
    setDescription(content);
  };

  const handleSave = () => {
    mutate({ ...data, description });
  };

  return (
    <Card extra={`w-full h-full sm:overflow-auto p-6  cursor-pointer `}>
      <p className="pb-4 text-[12px] text-[#979797]">
        Write Terms and Conditions
      </p>
      <ReactQuill
        key={data?._id}
        value={description}
        onChange={handleDescriptionChange}
        theme="snow"
      />
      <div className="flex justify-end">
        <button
          onClick={handleSave}
          className="font-base mt-4 w-[6em] rounded-xl bg-brand-500 py-[8px] text-sm text-white 
                  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Save
        </button>
      </div>
    </Card>
  );
};

export default TermsEditor;
