import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import routes from "routes";
import BookingsTable, { RowObj } from "./components/BookingsTable";
import { useGetOwnerReservations } from "hooks/bookings/useGetOwnerReservations";
import { FilteredRestaurantsPayload } from "api/bookings/bookings";
import ConfirmBookingModal from "./components/ConfirmBookinModal";

export default function BookingsView(props: { [x: string]: any }) {
  const { ...rest } = props;

  const filters: FilteredRestaurantsPayload = {
    limit: 10000,
    page: 0,
    filter: [],
    sort: [],
  };
  const { _id } = useParams();
  const { data, refetch } = useGetOwnerReservations(filters, _id);
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Bookings");
  const [selectedBooking, setSelectedBooking] = React.useState<RowObj>();
  const [isConfrimModalOpen, setIsConfirmModalOpen] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Bookings";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/bookings") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const handleConfirmBooking = (booking: RowObj) => {
    setSelectedBooking(booking);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/admin/bookings"
                  element={<Navigate to="/admin/bookings" replace />}
                />
              </Routes>
            </div>
          </div>
          <BookingsTable
            refetch={refetch}
            onConfirmBooking={handleConfirmBooking}
            tableData={data}
          />
          {selectedBooking && (
            <ConfirmBookingModal
              booking={selectedBooking}
              opened={isConfrimModalOpen}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          )}
        </main>
      </div>
    </div>
  );
}
