import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadLogo } from "api/restaurants/restaurants";

// useUploadLogo.ts
// useUploadLogo.ts
export const useUploadLogo = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((formData: FormData) => uploadLogo(formData), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["upload"]);
    },
    onError: () => {
      toast({
        title: "Failed to upload!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
