import { createContext } from "react";
import { AuthResponse, LoginResponse } from "interfaces/auth/auth";

export const defaultAuthResponse: LoginResponse = {
  dateOfBirth: "",
  email: "",
  firstName: "",
  id: "",
  isActive: false,
  isSocial: false,
  isVerified: false,
  lastName: "",
  gender: "",
  online: false,
  phoneNumber: "",
  roles: [],
  username: "",
  _id: "",
  __v: 0,
};

export const AuthContext = createContext<
  // eslint-disable-next-line no-unused-vars
  [LoginResponse, (authRepsone: LoginResponse) => void]
>([defaultAuthResponse, () => null]);

export const defaultTokenResponse: AuthResponse = {
  access_token: "",
  refresh_token: "",
};
export const TokenContext = createContext({
  token: defaultTokenResponse, // Your initial user state
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToken: (token: AuthResponse) => {}, // Function to update the user
});
