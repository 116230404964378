import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import routes from "routes";
import { EditBasicInfo } from "./components/EditBasicInfo";
import { useGetAllRestaurantById } from "hooks/restaurants/useGetRestaurantById";

export default function EditBasicInformationView(props: { [x: string]: any }) {
  const { _id } = useParams();
  const { data } = useGetAllRestaurantById(String(_id));
  const { ...rest } = props;
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === `/admin/restaurant/basic-information/${_id}`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={"Basic Information"}
              secondary={"Basic Information"}
              onClick={back}
              icon={"<"}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path={`/admin/restaurant/basic-information/${_id}`}
                  element={
                    <Navigate
                      to={`/admin/restaurant/basic-information/${_id}`}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </div>
          {data && <EditBasicInfo restaurant={data} />}
        </main>
      </div>
    </div>
  );
}
