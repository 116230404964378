import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMenuPhoto } from "api/restaurants/menu";
import { DeletePhotoMenuPayload } from "interfaces/restaurants/restaurants";

export const useDeletePhotoMenu = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: DeletePhotoMenuPayload) => deleteMenuPhoto(payload),
    {
      onSuccess: (_, variables, context: { refetch: () => void }) => {
        context?.refetch();
        queryClient.invalidateQueries(["photoMenu"]);
      },
      onError: () => {
        toast({
          title: "Failed to delete!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
