import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { UpdateUserObject, UsersResponse } from "interfaces/users/users";

export const getUsers = async (
  page: number,
  pageSize: number,
  sort: string
) => {
  const { data } = await axiosInstance.post<UsersResponse[]>(
    `${BaseUrl.DEVELOPMENT}/user/all/users`,
    { page, pageSize, sort }
  );

  return data;
};

export const updateUser = async (user: UpdateUserObject): Promise<number> => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/user/update-user/${user.userId}`,
    user.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
