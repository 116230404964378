export interface LoginResponse {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  isActive: boolean;
  isVerified: boolean;
  gender: string;
  phoneNumber: string;
  roles: Role[];
  _id: string;
  username: string;
  email: string;
  online: boolean;
  __v: number;
  isSocial: boolean;
  id: string;
}

export interface AuthResponse {
  access_token: string;
  refresh_token: string;
}

export interface RefreshTokenPayload {
  refresh_token: string;
}

export interface LoginPayload {
  username: string;
  password: string;
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface EmailPayload {
  code: string;
}

export interface ResetPasswordObject {
  code: string;
  payload: ResetPasswordPayload;
}

export interface ResetPasswordPayload {
  password: string;
  confirmPassword: string;
}

export interface UserUpdateObject {
  payload: UserUpdatePayload;
}

export interface UserUpdatePayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  gender: string;
}

export interface PasswordUpdateObject {
  payload: PasswordUpdatePayload;
}

export interface PasswordUpdatePayload {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export interface SignUpPayload {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
  roles: Role[];
}

export enum Role {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
  PLACE_OWNER = "PLACE_OWNER",
  STAFF = "STAFF",
}
