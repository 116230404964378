import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addGlobal } from "api/globals/globals";
import { AddGlobalPayload } from "interfaces/globals/globals";

export const useAddGlobals = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: AddGlobalPayload) => addGlobal(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Global was added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["globals"]);
    },
    onError: () => {
      toast({
        title: "Global failed to be added!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
