import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React, { useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import routes from "routes";
import { useGetFullMenu } from "hooks/menu/useGetFullMenu";
import Loader from "components/loader/Loader";
import AddCategoryModal from "./components/modals/AddCategoryModal";
import FullMenu from "./components/FullMenu";
import { UpdateMenuItems } from "interfaces/restaurants/menu";
import EditMenuItemModal from "./components/modals/EditMenuItemModal";

export default function FullMenuView(props: { [x: string]: any }) {
  const { _id } = useParams();
  const { data, isLoading } = useGetFullMenu(String(_id));
  const { ...rest } = props;
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState<{
    menuItem: UpdateMenuItems | null;
    open: boolean;
  }>({
    menuItem: null,
    open: false,
  });

  const handleEditMenuItem = (menuItem: UpdateMenuItems) => {
    setSelectedMenuItem({
      menuItem: menuItem,
      open: true,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === `/admin/restaurant/full-menu/${_id}`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={"Full Menu"}
              onClick={back}
              icon={"<"}
              secondary={"Full Menu"}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path={`/admin/restaurant/full-menu/${_id}`}
                  element={
                    <Navigate
                      to={`/admin/restaurant/full-menu/${_id}`}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </div>
          {data && data.length > 0 ? (
            <>
              <FullMenu onEditMenuItem={handleEditMenuItem} data={data} />
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col items-center justify-center pt-[15em]">
              <p>There are no categories, click the button to add.</p>
              <AddCategoryModal />
            </div>
          )}
          <EditMenuItemModal
            menuItem={selectedMenuItem.menuItem}
            opened={selectedMenuItem.open}
            onClose={() => {
              setSelectedMenuItem({
                menuItem: null,
                open: false,
              });
            }}
          />
        </main>
      </div>
    </div>
  );
}
