import { useGetUsers } from "hooks/users/useGetUsers";
import UsersTable from "./components/UsersTable";
import { useState } from "react";
import { UpdateUsers } from "interfaces/users/users";
import UsersModal from "./components/UsersModal";
import Loader from "components/loader/Loader";

const Users = () => {
  const { data, isLoading, refetch } = useGetUsers(1, 10000, "asc");
  const [selectedUsers, setSelectedUsers] = useState<{
    user: UpdateUsers | null;
    open: boolean;
  }>({
    user: null,
    open: false,
  });

  const handleEditUser = (user: UpdateUsers) => {
    setSelectedUsers({
      user: user,
      open: true,
    });
  };

  return (
    <div>
      <div className="mt-5  h-full gap-5 ">
        {data ? (
          <UsersTable
            refetch={refetch}
            onEditUser={handleEditUser}
            tableData={data}
          />
        ) : (
          isLoading && <Loader />
        )}
        <UsersModal
          user={selectedUsers.user}
          opened={selectedUsers.open}
          onClose={() => {
            setSelectedUsers({
              user: null,
              open: false,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Users;
