import { useQuery } from "@tanstack/react-query";
import {
  FilteredRestaurantsPayload,
  getOwnerReservations,
} from "api/bookings/bookings";

export const useGetOwnerReservations = (
  payload: FilteredRestaurantsPayload,
  restaurantId: string
) => {
  return useQuery(["bookings", payload, restaurantId], () =>
    getOwnerReservations(payload, restaurantId)
  );
};
