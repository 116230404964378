import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart } from "react-icons/md";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import { useGetRestaurantsReports } from "hooks/reports/useGetRestaurantsReports";
import { useGetUsersReports } from "hooks/reports/useGetUsersReports";
import { useGetReservationsReports } from "hooks/reports/useGetReservationsResports";
import { useGetAllReservationsReports } from "hooks/reports/useGetAllReservationsReports";

const Dashboard = () => {
  const { data: restaurantReports } = useGetRestaurantsReports();
  const { data: userReports } = useGetUsersReports();
  const { data: resevarionsReports } = useGetReservationsReports();
  const { data: reservationReports, refetch } = useGetAllReservationsReports();

  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Restaurant Reports"}
          subtitle={restaurantReports?.count}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Users Reports"}
          subtitle={userReports?.count}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Reservations Reports"}
          subtitle={resevarionsReports?.count}
        />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-1">
        <div>
          {reservationReports && (
            <CheckTable refetch={refetch} tableData={reservationReports} />
          )}
        </div>
        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-1">
          <DailyTraffic />
          <PieChartCard />
        </div> */}
        {/* <ComplexTable tableData={tableDataComplex} /> */}
      </div>
    </div>
  );
};

export default Dashboard;
