import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import {
  Avatar,
  Flex,
  Tab,
  TabList,
  TabPanels,
  Table,
  Tabs,
} from "@chakra-ui/react";
import { GrFormEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

type RowObj = {
  _id: string;
  name: string;
  city: string;
  street: string;
  zip: string;
  state: string;
  email: string;
  phoneNumber: string;
  isVerifiedStatus: string;
  logo: {
    path: string;
  };
  edit: boolean;
};

function AdminRestaurantTable(props: { tableData: any; refetch: () => void }) {
  const navigate = useNavigate();
  const { tableData, refetch } = props;

  const handleEditClick = (_id: string) => {
    navigate(`/admin/restaurant/view/${_id}`);
  };

  const itemsPerPage = 50;
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
    setCurrentPage(1);
  };

  useEffect(() => {
    //
  }, [refetch()]);

  const getTabStatus = (index: number) => {
    switch (index) {
      case 0:
        return "PENDING";
      case 1:
        return "APPROVED";
      case 2:
        return "DECLINED";
      default:
        return "";
    }
  };

  const filteredData = tableData.filter(
    (row: RowObj) => row.isVerifiedStatus === getTabStatus(currentTab)
  );

  useEffect(() => {
    //handle smth
  }, [props.tableData]);

  const columnNames = [
    "Restaurant Name",
    "Address",
    "Email",
    "Phone Number",
    "Status",
    "Edit",
  ];

  const getStatusColor = (status: string) => {
    switch (status) {
      case "PENDING":
        return "#7BC3E5";
      case "APPROVED":
        return "#83A44D";
      case "DECLINED":
        return "#C64343";
      default:
        return "";
    }
  };

  return (
    <Tabs
      onChange={handleTabChange}
      isFitted
      variant="line"
      colorScheme="twitter"
      mt={4}
    >
      <TabList sx={{ borderBottom: "none" }} className="mb-6 w-[50%]">
        <Tab style={{ fontSize: "14px" }} value="PENDING">
          Review
        </Tab>
        <Tab style={{ fontSize: "14px" }} value="APPROVED">
          Approved Restaurants
        </Tab>
        <Tab style={{ fontSize: "14px" }} value="DECLINED">
          Declined Restaurants
        </Tab>
      </TabList>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <TabPanels>
            <Table className="w-full">
              <thead>
                <tr className="!border-px !border-gray-400">
                  {columnNames.map((columnName, index) => (
                    <th
                      key={index}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-sm text-[#a3aed0]">
                        {columnName}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData
                  .slice(startIndex, endIndex)
                  .map((row: RowObj, index: number) => (
                    <tr key={index}>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <Flex alignItems="center">
                          {row.logo && row.logo.path ? (
                            <Avatar size="xs" src={row.logo.path} mr={2} />
                          ) : null}
                          <p className="text-black text-sm font-bold dark:text-white">
                            {row.name}
                          </p>
                        </Flex>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.street}, {row.zip}, {row.city}, {row.state}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.email ? row.email : "N/A"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          +{row.phoneNumber}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p
                          style={{
                            color: getStatusColor(row.isVerifiedStatus),
                          }}
                          className="text-sm font-bold dark:text-white"
                        >
                          {row.isVerifiedStatus.charAt(0) +
                            row.isVerifiedStatus.slice(1).toLowerCase()}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <GrFormEdit
                          onClick={() => handleEditClick(row._id)}
                          className="text-black h-6 w-6 cursor-pointer font-bold dark:text-white"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </TabPanels>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
            py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
            py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </Tabs>
  );
}

export default AdminRestaurantTable;
