import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useCreateSetMenu } from "hooks/menu/useCreateSetMenu";
import { useParams } from "react-router-dom";

const SetMenuModal: React.FC = () => {
  const { _id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useCreateSetMenu(String(_id));

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: "",
    },
    onSubmit: (values) => {
      const payload = {
        ...values,
        price: parseFloat(values.price),
      };

      mutate(payload, {
        onSuccess: () => {
          formik.resetForm({
            values: {
              name: "",
              description: "",
              price: "",
            },
          });
          onClose();
        },
        onError: () => {
          formik.resetForm({
            values,
          });
        },
      });
    },
  });

  return (
    <>
      <button
        onClick={onOpen}
        className="font-base mt-4 w-[9em] rounded-xl bg-brand-500 py-[8px] text-sm text-white 
                    dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        Add Set Menu
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Set Menu</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="w-full">
                  <InputField
                    {...formik.getFieldProps("name")}
                    variant="auth"
                    extra="mb-3"
                    label="Set Menu Name"
                    placeholder="Enter your Set Menu Name"
                    id="name"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("description")}
                    variant="auth"
                    extra="mb-3"
                    label="Description (optional)"
                    placeholder="Enter your Description"
                    id="description"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("price")}
                    variant="auth"
                    extra="mb-3"
                    label="Price"
                    placeholder="Enter your Price"
                    id="price"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="pl-4" />
                <button className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white">
                  Add Set Menu
                </button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormikProvider>
      </Modal>
    </>
  );
};

export default SetMenuModal;
