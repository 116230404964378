import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { Role } from "interfaces/auth/auth";

export interface Waiter {
  _id: string;
  restaurantId: {
    location: {
      type: string;
      coordinates: [number, number];
    };
    _id: string;
    name: string;
    email: string;
    website: string;
    aboutUs: string;
    avgPrice: {
      from: number;
      to: number;
    };
    image: string[];
    avgRating: null | number;
    phoneNumber: string;
    street: string;
    state: string;
    city: string;
    zip: string;
    isVerifiedStatus: string;
    userId: string;
    socialMedia: {
      facebook: string;
      insta: string;
      twitter: string;
      tiktok: string;
      _id: string;
    };
    logo: string;
    openingHours: {
      day: string;
      startTime: string;
      endTime: string;
      workDay: boolean;
    }[];
    published: boolean;
    __v: number;
  };
  userId: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  active: boolean;
  staffType: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface WaiterPayload {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dateOfBirth: string;
  gender: string;
  roles: Role[];
}

export const addWaiter = async (
  payload: WaiterPayload,
  restaurantId: string
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/manual-registration/register/staff/restaurant/${restaurantId}`,
    payload
  );

  return data;
};

export const getWaiters = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<Waiter[]>(
    `${BaseUrl.DEVELOPMENT}/place-staff/restaurants/${restaurantId}`
  );

  return data;
};
