/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { MdLogout } from "react-icons/md";
import routes from "routes";
import LogoutModal from "components/modals/LogoutModal";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const navigate = useNavigate();
  const [user] = useContext(AuthContext);

  const logOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token_expiration");
    localStorage.removeItem("useObject");
    navigate("/auth/login");
    window.location.reload();
  };

  const filteredRoutes = routes.filter((route) =>
    route.roles ? route.roles.some((role) => user.roles.includes(role)) : true
  );

  return (
    <>
      <div
        className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
          open ? "translate-x-0" : "-translate-x-96"
        }`}
      >
        <span
          className="absolute right-4 top-4 block cursor-pointer xl:hidden"
          onClick={onClose}
        >
          <HiX />
        </span>
        <div className={`mx-[56px] mt-[50px] flex items-center`}>
          <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold text-navy-700 dark:text-white">
            MealX.
          </div>
        </div>
        <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
        <ul className="mb-auto pt-1">
          <Links routes={filteredRoutes} />
          <div
            onClick={logOut}
            className="relative mb-3 flex  hover:cursor-pointer"
          >
            <h1 className="my-[3px] flex cursor-pointer items-center gap-4 px-[2.1rem]">
              <MdLogout className="h-6 w-6 font-medium text-gray-600" />
              <span className="font-medium text-gray-600">Log out</span>
            </h1>
          </div>
        </ul>
      </div>
      <LogoutModal />
    </>
  );
};

export default Sidebar;
