import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDeleteOffer } from "hooks/offers/useDeleteOffer";
import { OffersResponse } from "interfaces/offers/offers";

export interface Props {
  offer: OffersResponse | null;
  opened: boolean;
  onClose: () => void;
}

const DeleteOfferModal: React.FC<Props> = ({ offer, opened, onClose }) => {
  const { mutate } = useDeleteOffer();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    mutate(offer._id, {
      onSuccess: () => {
        handleClose();
      },
    });
  };

  return (
    <>
      <Modal isOpen={opened} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Offer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1>Are you sure you want to delete this offer?</h1>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
            <div className="pl-4" />
            <button
              onClick={handleSubmit}
              className="mt-2 w-[8em] rounded-xl bg-red-500 py-[12px] text-base font-medium text-white"
            >
              Delete Offer
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteOfferModal;
