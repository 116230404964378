import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import routes from "routes";
import WaitersTable from "./components/WaitersTable";
import { useGetWaiters } from "hooks/staff/useGetWaiters";
import Loader from "components/loader/Loader";
import AddWaiterModal from "./components/modals/AddWaiterModal";

export default function WaitersView(props: { [x: string]: any }) {
  const { _id } = useParams();
  const { data, isLoading } = useGetWaiters(String(_id));
  const { ...rest } = props;
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === `/admin/waiters/${_id}`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const back = () => {
    navigate(-1);
  };
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={"Waiters"}
              onClick={back}
              icon={"<"}
              secondary={"Waiters"}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path={`/admin/waiters/${_id}`}
                  element={<Navigate to={`/admin/waiters/${_id}`} replace />}
                />
              </Routes>
            </div>
          </div>
          {data && data.length > 0 ? (
            <WaitersTable tableData={data} />
          ) : isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col items-center justify-center pt-[15em]">
              <p>
                There are no waiters, click the button to create new waiter.
              </p>
              <AddWaiterModal />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
