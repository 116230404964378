import { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import ReactMapGL, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function Map({ coordinates }: { coordinates: any }) {
  const [viewState, setViewState] = useState({
    latitude: coordinates?.latitude || 42.6629,
    longitude: coordinates?.longitude || 21.1655,
    zoom: 15,
  });

  useEffect(() => {
    setViewState({
      latitude: coordinates?.latitude || 42.6629,
      longitude: coordinates?.longitude || 21.1655,
      zoom: 15,
    });
  }, [coordinates.latitude, coordinates.longitude]);

  return (
    <ReactMapGL
      mapboxAccessToken="pk.eyJ1Ijoiam9uYmFsYWEiLCJhIjoiY2xydXBvYmU0MGYzNTJsbXJpdW94dTg2aSJ9.-oPQP7bEx5uTemsneAd7YQ"
      mapStyle="mapbox://styles/jonbalaa/clruqc52800mw01pe7z3o8mk8"
      attributionControl={false}
      {...viewState}
      onMove={(viewport) => setViewState(viewport.viewState)}
    >
      <div>
        <Marker
          latitude={coordinates.latitude}
          longitude={coordinates.longitude}
        >
          <MdLocationPin style={{ color: "red" }} className="h-16 w-12" />
        </Marker>
      </div>
    </ReactMapGL>
  );
}

export default Map;
