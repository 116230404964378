import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
import { FilteredRestaurantsPayload } from "api/bookings/bookings";
import { useGetAllOwnerReservations } from "hooks/bookings/useGetAllOwnerReservations";
import BookingsTable, { RowObj } from "../bookings/components/BookingsTable";
import ConfirmBookingModal from "../bookings/components/ConfirmBookinModal";

export default function OwnerReservationsView() {
  const filters: FilteredRestaurantsPayload = {
    limit: 10000,
    page: 0,
    filter: [],
    sort: [],
  };
  const { data, refetch } = useGetAllOwnerReservations(filters);
  const [selectedBooking, setSelectedBooking] = React.useState<RowObj>();
  const [isConfrimModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/owner-reservations") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const handleConfirmBooking = (booking: RowObj) => {
    setSelectedBooking(booking);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        <div className="h-full">
          <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
            <Routes>
              {getRoutes(routes)}

              <Route
                path="/admin/owner-reservations"
                element={<Navigate to="/admin/owner-reservations" replace />}
              />
            </Routes>
          </div>
        </div>
        <BookingsTable
          refetch={refetch}
          onConfirmBooking={handleConfirmBooking}
          tableData={data}
        />
        {selectedBooking && (
          <ConfirmBookingModal
            booking={selectedBooking}
            opened={isConfrimModalOpen}
            onClose={() => setIsConfirmModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
}
