import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadGalleryPhotos } from "api/restaurants/restaurants";

export const useUploadGalleryPhotos = () => {
  const queryClient = useQueryClient();

  return useMutation((formData: FormData) => uploadGalleryPhotos(formData), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["uploadGallery"]);
    },
  });
};
