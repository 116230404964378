import Card from "components/card";
import {
  FullMenu as FullMenuRepsonse,
  MenuItems,
  UpdateMenuItems,
} from "interfaces/restaurants/menu";
import AddCategoryModal from "./modals/AddCategoryModal";
import AddMenuItemModal from "./modals/AddMenuItemModal";
import { SetStateAction, useState } from "react";
import { FaPencil } from "react-icons/fa6";

interface FullMenuProps {
  data: FullMenuRepsonse[];
  onEditMenuItem: (
    menuItem: UpdateMenuItems
  ) => SetStateAction<UpdateMenuItems> | void;
  menuItem?: UpdateMenuItems;
}

const FullMenu: React.FC<FullMenuProps> = ({ data, onEditMenuItem }) => {
  const [expandedMenuIndex, setExpandedMenuIndex] = useState<number | null>(
    null
  );

  const toggleMenu = (index: number) => {
    setExpandedMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      {data.map((menu, index) => (
        <div className="mb-4" key={menu._id}>
          <Card extra={`w-full h-full sm:overflow-auto px-6  cursor-pointer `}>
            <header className="relative flex cursor-pointer flex-row items-center justify-between  pb-4 pt-4">
              <div className="w-full">
                <h1 onClick={() => toggleMenu(index)} className="font-bold">
                  {menu.name}
                </h1>
                <p onClick={() => toggleMenu(index)} className="text-sm">
                  {menu.description}
                </p>
              </div>
              <AddMenuItemModal name={menu.name} _id={menu._id} />
            </header>
          </Card>
          <div
            className={`mb-6 rounded-b-lg bg-[#f1efef] pl-8 ${
              index === expandedMenuIndex ? "hidden" : "block"
            }`}
          >
            {menu.menuItems.map((menuItem: MenuItems) => (
              <div
                className="flex w-full flex-row justify-between pb-6 pr-6 pt-4"
                key={menuItem._id}
              >
                <div className="flex w-[50%] flex-row items-center gap-4">
                  <p className="font-bold">{menuItem.name}:</p>
                  <p className="text-sm text-[#AEAEAE]">
                    {menuItem.description}
                  </p>
                  <p className=" text-sm font-bold">{menuItem.price}$</p>
                </div>
                <div>
                  <FaPencil
                    className="cursor-pointer"
                    onClick={() => onEditMenuItem(menuItem)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="flex justify-end">
        <AddCategoryModal />
      </div>
    </>
  );
};

export default FullMenu;
