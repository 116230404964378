import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";

export interface Reports {
  count: number;
}

interface ReservationGraph {
  _id: {
    year: number;
    month: number;
  };
  totalReservations: number;
  cancelledReservations: number;
  approvedReservations: number;
}

export interface ReportGraph {
  count: number;
  month?: number | null;
  year?: number | null;
}

interface Logs {
  _id: string;
  timestamp: string;
  level: string;
  message: string;
  meta: {
    user: {
      userId: string | null;
      username: string | null;
    } | null;
    method: string;
    originalUrl: string;
    statusCode: number;
    duration: number;
    body: {
      username?: string;
      password?: string;
      name?: string;
      email?: string;
      website?: string;
      aboutUs?: string;
      phoneNumber?: string;
      openingHours?: {
        day: string;
        startTime: string;
        endTime: string;
        workDay: boolean;
      }[];
    };
    _id: string;
  };
}

interface UserCount {
  _id: string;
  totalUsers: number;
}

interface Reservation {
  hasArrived: boolean;
  status: "RESERVED" | "CANCELLED" | "INITIALLY_RESERVED";
  guestsNumber: number;
  specialRequests: string;
}

export const getRestaurantsReports = async () => {
  const { data } = await axiosInstance.get<Reports>(
    `${BaseUrl.DEVELOPMENT}/reports/getRestaurants`
  );

  return data;
};

export const getUsersReports = async () => {
  const { data } = await axiosInstance.get<Reports>(
    `${BaseUrl.DEVELOPMENT}/reports/getUsersByDateRange`
  );

  return data;
};

export const getReservationsReports = async () => {
  const { data } = await axiosInstance.get<Reports>(
    `${BaseUrl.DEVELOPMENT}/reports/getReservationsByDateRange`
  );

  return data;
};

export const getUsersPerMonthReports = async () => {
  const { data } = await axiosInstance.get<ReportGraph[]>(
    `${BaseUrl.DEVELOPMENT}/reports/getUsersPerMonth`
  );

  return data;
};

export const getMostBookedRestaurants = async () => {
  const { data } = await axiosInstance.get<Reports>(
    `${BaseUrl.DEVELOPMENT}/reports/getMostBookedRestaurants`
  );

  return data;
};

export const getCountByReservations = async (month: string) => {
  const { data } = await axiosInstance.get<ReservationGraph[]>(
    `${BaseUrl.DEVELOPMENT}/reports/count-by-reservations?timeUnit=${month}`
  );

  return data;
};

export const getCountByUser = async (month: string) => {
  const { data } = await axiosInstance.get<UserCount[]>(
    `${BaseUrl.DEVELOPMENT}/reports/count-by-user?timeUnit=${month}`
  );

  return data;
};

export const getAllReservationsReports = async () => {
  const { data } = await axiosInstance.get<Reservation[]>(
    `${BaseUrl.DEVELOPMENT}/reports/get-all-reservations`
  );

  return data;
};

export const getLogs = async () => {
  const { data } = await axiosInstance.get<Logs[]>(
    `${BaseUrl.DEVELOPMENT}/logs`
  );

  return data;
};
