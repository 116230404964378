import Card from "components/card";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import { useParams } from "react-router-dom";
import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import routes from "routes";

export default function AddRestaurantsView(props: { [x: string]: any }) {
  const navigate = useNavigate();
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Restaurant");
  const { _id } = useParams();

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes: RoutesType[]): string | boolean => {
    let activeRoute = "Add New Restaurant";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/restaurant/add") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const addBasicInfo = () => {
    navigate(`/admin/restaurant/basic-information/${_id}`);
  };

  const averagePrice = () => {
    navigate(`/admin/restaurant/average-price/${_id}`);
  };

  const openingHours = () => {
    navigate(`/admin/restaurant/opening-hours/${_id}`);
  };

  const galleryPhoto = () => {
    navigate(`/admin/restaurant/gallery-photo/${_id}`);
  };

  const customizeDetails = () => {
    navigate(`/admin/restaurant/customize-details/${_id}`);
  };

  const restaurantMenu = () => {
    navigate(`/admin/restaurant/menu/${_id}`);
  };

  const offers = () => {
    navigate(`/admin/offers/${_id}`);
  };

  const reservations = () => {
    navigate(`/admin/bookings/${_id}`);
  };

  const waiters = () => {
    navigate(`/admin/waiters/${_id}`);
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/admin/restaurant/add"
                  element={<Navigate to="/admin/restaurant/add" replace />}
                />
              </Routes>
            </div>
          </div>
          <Card
            onClick={addBasicInfo}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header
              className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
            >
              <div>
                <h1
                  className={`pb-2 text-xl font-bold text-navy-700 dark:text-white `}
                >
                  Add Basic Informations
                </h1>
                <p className={`pb-4 text-sm `}>
                  The Basic Information section allows you to customize the
                  essential pieces of your restaurant profile
                </p>
              </div>
            </header>
          </Card>
          <Card
            onClick={openingHours}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header
              className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
            >
              <div>
                <h1 className={`pb-2 text-xl font-bold `}>Add Opening Hours</h1>
                <p className={`pb-4 text-sm`}>Set your opening hours</p>
              </div>
            </header>
          </Card>
          <Card
            onClick={averagePrice}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold  `}>
                  Add Average Price
                </h1>
                <p className={`pb-4 text-sm `}>
                  Tell your guests about your prices.
                </p>
              </div>
            </header>
          </Card>
          <Card
            onClick={galleryPhoto}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold  `}>
                  Add Gallery Photo
                </h1>
                <p className={`pb-4 text-sm `}>
                  Use your Gallery Photos to enhance your restaurant Page, you
                  can add or delete them.
                </p>
              </div>
            </header>
          </Card>
          <Card
            onClick={customizeDetails}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold  `}>
                  Customize the details of your restaurant profile
                </h1>
                <p className={`pb-4 text-sm`}>
                  The Details section allows you to add many key details that
                  diners search for when booking a reservation.
                </p>
              </div>
            </header>
          </Card>
          <Card
            onClick={restaurantMenu}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold  `}>
                  Add Restaurant Menu
                </h1>
                <p className={`pb-4 text-sm`}>Add details about your menu.</p>
              </div>
            </header>
          </Card>
          <Card
            onClick={offers}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold  `}>Offers</h1>
                <p className={`pb-4 text-sm `}>
                  Create offers based on your restaurant.
                </p>
              </div>
            </header>
          </Card>
          <Card
            onClick={reservations}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold `}>Reservations</h1>
                <p className={`pb-4 text-sm `}>Set reservations dates</p>
              </div>
            </header>
          </Card>
          <Card
            onClick={waiters}
            extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
          >
            <header className="relative flex flex-row items-center justify-between pt-4">
              <div>
                <h1 className={`pb-2 text-xl font-bold `}>Waiters</h1>
                <p className={`pb-4 text-sm `}>Add and View Waiters</p>
              </div>
            </header>
          </Card>
        </main>
      </div>
    </div>
  );
}
