import { Checkbox, Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useLogin } from "hooks/api/useLogin";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useToken } from "hooks/api/useToken";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be 8+ characters"),
  username: Yup.string()
    .email("Invalid email address")
    .matches(/^[^@]+@[^@]+\.[^@]+$/, "Invalid email address")
    .required("Email is required"),
});

export const LoginForm: React.FC = () => {
  const { mutate, isLoading } = useLogin();
  const { refetch } = useToken();
  const navigate = useNavigate();

  const forgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            navigate("/admin/restaurant");
            refetch();
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid =
    formik.isValid && formik.values.password && formik.values.username;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <InputField
          {...formik.getFieldProps("username")}
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Enter your username"
          id="username"
          type="text"
          onChange={formik.handleChange}
        />
        <InputField
          {...formik.getFieldProps("password")}
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter your password"
          id="password"
          type="password"
          onChange={formik.handleChange}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            onClick={forgotPassword}
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <button
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          disabled={!isFormInvalid}
        >
          {isLoading ? <Spinner /> : "Log In"}
        </button>
      </form>
    </FormikProvider>
  );
};
