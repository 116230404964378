import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateRestaurant } from "api/restaurants/restaurants";
import { RestaurantPayload } from "interfaces/restaurants/restaurants";

export const useUpdateRestaurant = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: RestaurantPayload) => updateRestaurant(payload),
    {
      onSuccess: (_, variables, context: { refetch: () => void }) => {
        toast({
          title: "Restaurant was updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        context?.refetch();
        queryClient.invalidateQueries(["restaurants", variables._id]);
      },
      onError: () => {
        toast({
          title: "Restaurant failed to update!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
