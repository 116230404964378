import Card from "components/card";
import { FormLabel, Select } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useAddOffer } from "hooks/offers/useAddOffer";
import { useGetSetMenu } from "hooks/menu/useGetSetMenu";
import { useState } from "react";

export const AddOffer: React.FC = () => {
  const { _id } = useParams();
  const { mutate } = useAddOffer();
  const { data } = useGetSetMenu(String(_id));
  const [menuType, setMenuType] = useState("");
  const menu = ["Full Menu", "Set Menu"];
  const percentage = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const formik = useFormik({
    initialValues: {
      title: "",
      percentage: 0,
      fromDate: new Date(),
      toDate: new Date(),
      restaurantId: _id,
      fromTime: "",
      menuId: "",
      toTime: "",
      status: "",
      menuType: "",
    },
    onSubmit: (values, { resetForm }) => {
      const percentageValue = parseInt(String(values.percentage));
      let payload = {
        ...values,
        percentage: percentageValue,
        restaurantId: _id,
        fromDate: new Date(values.fromDate),
        toDate: new Date(values.toDate),
      };

      if (values.menuType === "SET_MENU") {
        if (values.menuId) {
        } else {
          console.error("Set Menu is not selected");
          return;
        }
        delete payload.percentage;
      }

      mutate(payload, {
        onSuccess: () => {
          resetForm();
          setMenuType("");
        },
        onError: () => {
          resetForm();
          setMenuType("");
        },
      });
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Card
          extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
        >
          <header
            className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
          >
            <div className="w-full">
              <div className="flex w-full flex-col gap-4 pb-6 md:flex-row">
                <InputField
                  {...formik.getFieldProps("title")}
                  variant="auth"
                  extra="mb-3"
                  label="Title"
                  placeholder="Title"
                  id="title"
                  type="text"
                  onChange={formik.handleChange}
                />
                <div className="w-full md:w-[10.8em]">
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Menu
                  </FormLabel>
                  <Select
                    sx={{
                      borderRadius: "0.75rem",
                      height: "3.5em",
                      fontSize: "14px",
                    }}
                    className="mb-3 flex items-center justify-center border bg-white/0  outline-none"
                    {...formik.getFieldProps("menuType")}
                    onChange={(e) => {
                      formik.setFieldValue("menuType", e.target.value);
                      setMenuType(e.target.value);
                    }}
                    variant="auth"
                    placeholder="Select Menu"
                    id="menu"
                  >
                    {menu.map((s) => (
                      <option
                        key={s}
                        value={s === "Full Menu" ? "FULL_MENU" : "SET_MENU"}
                      >
                        {s}
                      </option>
                    ))}
                  </Select>
                </div>
                {menuType === "FULL_MENU" && (
                  <div className="w-full md:w-[10.8em]">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Percentage
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                      }}
                      className="mb-3 flex items-center justify-center border bg-white/0  outline-none"
                      {...formik.getFieldProps("percentage")}
                      variant="auth"
                      placeholder="Select Percentage"
                      id="percentage"
                    >
                      {percentage.map((s) => (
                        <option key={s} value={s}>{`${s}% Off`}</option>
                      ))}
                    </Select>
                  </div>
                )}
                {menuType === "SET_MENU" && (
                  <div className="w-full md:w-[10.8em]">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Set Menu
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                      }}
                      className="mb-3 flex items-center justify-center border bg-white/0  outline-none"
                      {...formik.getFieldProps("menuId")}
                      variant="auth"
                      placeholder="Select Set Menu"
                      id="menuId"
                    >
                      {data?.map((setMenu) => (
                        <option
                          key={setMenu._id}
                          value={setMenu._id}
                        >{`${setMenu.name}`}</option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className="w-full md:w-[10.8em]">
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Status
                  </FormLabel>
                  <Select
                    sx={{
                      borderRadius: "0.75rem",
                      height: "3.5em",
                      fontSize: "14px",
                    }}
                    className="mb-3 flex items-center justify-center border bg-white/0  outline-none"
                    {...formik.getFieldProps("status")}
                    variant="auth"
                    placeholder="Select Status"
                    id="status"
                  >
                    {["Active", "Non-Active"].map((s) => (
                      <option
                        key={s}
                        value={s === "Active" ? "ACTIVE" : "NON_ACTIVE"}
                      >
                        {s}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex w-full flex-col gap-4 pb-6 md:flex-row">
                <div>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Date (from)
                  </FormLabel>
                  <input
                    type="date"
                    {...formik.getFieldProps("fromDate")}
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="From"
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[16em] text-right text-sm md:pl-[3em]"
                  />
                </div>
                <div>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Date (to)
                  </FormLabel>
                  <input
                    type="date"
                    {...formik.getFieldProps("toDate")}
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="From"
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[16em] text-right text-sm md:pl-[3em]"
                  />
                </div>
                <div>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Hour (from)
                  </FormLabel>
                  <input
                    type="time"
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="From"
                    {...formik.getFieldProps("fromTime")}
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[20em] text-right text-sm md:pl-[6.6em]"
                  />
                </div>
                <div>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      paddingLeft: "0.3em",
                    }}
                  >
                    Hour (to)
                  </FormLabel>
                  <input
                    type="time"
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="To"
                    {...formik.getFieldProps("toTime")} // Spread Formik field props here
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[20em] text-right text-sm md:pl-[6.6em]"
                  />
                </div>
              </div>
            </div>
          </header>
        </Card>
        <div className="flex justify-end pb-6">
          <button
            className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save
          </button>
        </div>
      </form>
    </FormikProvider>
  );
};
