import MainDashboard from "views/admin/default";
import { MdHome, MdAddBusiness } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import Users from "views/admin/users";
import { IoMdSettings } from "react-icons/io";
import { TbNotes } from "react-icons/tb";
import RestaurantsView from "views/admin/restaurants";
import { Role } from "interfaces/auth/auth";
import SettingsView from "views/admin/settings";
import TermsView from "views/terms";
import { MdOutlineMenuBook } from "react-icons/md";
import { IoIosInformationCircle } from "react-icons/io";
import AllBookingsView from "views/admin/bookings/allBookings";
import OwnerReservationsView from "views/admin/reservations";
import LogsView from "views/admin/logs";

const routes: RoutesType[] = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    roles: [Role.ADMIN],
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <FaUser className="h-5 w-5" />,
    component: <Users />,
    secondary: true,
    roles: [Role.ADMIN],
  },
  {
    name: "Restaurant",
    layout: "/admin",
    path: "restaurant",
    icon: <MdAddBusiness className="h-6 w-6" />,
    component: <RestaurantsView />,
    roles: [Role.ADMIN, Role.PLACE_OWNER],
  },
  {
    name: "Owner Reservations",
    layout: "/admin",
    path: "owner-reservations",
    icon: <MdOutlineMenuBook className="h-6 w-6" />,
    component: <OwnerReservationsView />,
    roles: [Role.PLACE_OWNER],
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <IoMdSettings className="h-6 w-6" />,
    component: <SettingsView />,
    roles: [Role.ADMIN],
  },
  {
    name: "Terms of use",
    layout: "/admin",
    path: "terms-of-use",
    icon: <TbNotes className="h-6 w-6" />,
    component: <TermsView />,
    roles: [Role.ADMIN],
  },
  {
    name: "Reservations",
    layout: "/admin",
    path: "reservations",
    icon: <MdOutlineMenuBook className="h-6 w-6" />,
    component: <AllBookingsView />,
    roles: [Role.ADMIN],
  },
  {
    name: "Logs",
    layout: "/admin",
    path: "logs",
    icon: <IoIosInformationCircle className="h-6 w-6" />,
    component: <LogsView />,
    roles: [Role.ADMIN],
  },
];
export default routes;
