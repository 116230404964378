import { useQuery } from "@tanstack/react-query";
import { validateToken } from "api/auth/auth";
import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect } from "react";

const TOKEN_STORAGE_KEY = "useObject";

export const useToken = () => {
  const [, setUserContext] = useContext(AuthContext);
  const { data, error, isSuccess, refetch } = useQuery(
    ["validateToken"],
    () => validateToken(),
    {
      retry: false,
    }
  );

  useEffect(() => {
    if (isSuccess && data) {
      setUserContext(data);
      localStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(data));
    }
  }, [isSuccess, data, setUserContext]);

  useEffect(() => {
    const storedData = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (storedData) {
      setUserContext(JSON.parse(storedData));
    }
  }, [setUserContext]);

  return { data, error, isSuccess, refetch };
};
