import Card from "components/card";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantResponse } from "interfaces/restaurants/restaurants";
import { useUpdateRestaurantOwner } from "hooks/restaurants/useUpdateRestaurantOwner";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";

interface AddAveragePriceProps {
  data: RestaurantResponse;
  refetch: () => void;
}

export const AddAveragePrice: React.FC<AddAveragePriceProps> = ({
  data,
  refetch,
}) => {
  const { _id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutate } = useUpdateRestaurantOwner();

  const formik = useFormik({
    initialValues: {
      from: data?.avgPrice?.from || "",
      to: data?.avgPrice?.to || "", // Using optional chaining to safely access 'to'
    },
    onSubmit: async (values) => {
      const { from, to } = values;
      if (!from || !to) {
        toast({
          title: "Please provide both 'from' and 'to' values.",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
        return;
      }
      try {
        mutate({ _id: _id, avgPrice: { from: Number(from), to: Number(to) } });
        refetch();
        navigate(`/admin/restaurant/add/${_id}`);
      } catch (error) {
        console.error("Mutation failed:", error);
      }
    },
  });

  useEffect(() => {
    // Refetch on refetch change
  }, [refetch]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Card
          extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
        >
          <header
            className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
          >
            <div>
              <p className={`pb-4 text-sm font-bold`}>
                What is the typical check amount per guest at your restaurant?
              </p>
              <div className="flex flex-row gap-4 pb-6">
                <InputField
                  {...formik.getFieldProps("from")}
                  variant="auth"
                  extra="mb-3"
                  label="From"
                  placeholder="Min. price"
                  id="from"
                  type="text"
                  onChange={formik.handleChange}
                />
                <InputField
                  {...formik.getFieldProps("to")}
                  variant="auth"
                  extra="mb-3"
                  label="To"
                  placeholder="Max. price"
                  id="to"
                  type="text"
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </header>
        </Card>
        <div className="flex justify-end">
          <button
            className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save Changes
          </button>
        </div>
      </form>
    </FormikProvider>
  );
};
