import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";

export const Menu: React.FC = () => {
  const { _id } = useParams();
  const navigate = useNavigate();

  const urlMenu = () => {
    navigate(`/admin/restaurant/url-menu/${_id}`);
  };

  const fullMenu = () => {
    navigate(`/admin/restaurant/full-menu/${_id}`);
  };

  const setMenu = () => {
    navigate(`/admin/restaurant/set-menu/${_id}`);
  };

  const photoMenu = () => {
    navigate(`/admin/restaurant/photo-menu/${_id}`);
  };

  return (
    <>
      <Card
        onClick={urlMenu}
        extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
      >
        <header
          className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div>
            <p className={`pb-4 text-lg font-bold`}>URL Menu</p>
          </div>
        </header>
      </Card>
      <Card
        onClick={fullMenu}
        extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
      >
        <header
          className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div>
            <p className={`pb-4 text-lg font-bold`}>Full Menu</p>
          </div>
        </header>
      </Card>
      <Card
        onClick={setMenu}
        extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
      >
        <header
          className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div>
            <p className={`pb-4 text-lg font-bold`}>Set Menu</p>
          </div>
        </header>
      </Card>
      <Card
        onClick={photoMenu}
        extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
      >
        <header
          className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div>
            <p className={`pb-4 text-lg font-bold`}>Photo Menu</p>
          </div>
        </header>
      </Card>
    </>
  );
};
