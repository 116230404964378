import { useEffect, useState } from "react";
import Card from "components/card";
import {
  IoChevronForwardCircleSharp,
  IoChevronBackCircleSharp,
} from "react-icons/io5";
import { Table } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

interface Logs {
  _id: string;
  timestamp: string;
  level: string;
  message: string;
  meta: {
    user: {
      userId: string | null;
      username: string | null;
    } | null;
    method: string;
    originalUrl: string;
    statusCode: number;
    duration: number;
    body: {
      username?: string;
      password?: string;
      name?: string;
      email?: string;
      website?: string;
      aboutUs?: string;
      phoneNumber?: string;
      openingHours?: {
        day: string;
        startTime: string;
        endTime: string;
        workDay: boolean;
      }[];
    };
    _id: string;
  };
}

function LogsTable(props: { tableData?: Logs[]; refetch?: () => void }) {
  const { tableData } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<"userId" | "username" | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const itemsPerPage = 50;
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (columnName: "userId" | "username") => {
    if (sortBy === columnName) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortBy(null);
        setSortOrder("asc");
      }
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    // Handle data updates if needed
  }, [props.tableData]);

  const sortedData = tableData
    ?.filter((row: Logs) =>
      row.meta.user
        ? row.meta.user.userId
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          row.meta.user.username
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase())
        : false
    )
    ?.sort((a: Logs, b: Logs) => {
      if (sortBy === "userId" || sortBy === "username") {
        // Updated "userName" to "username"
        const valueA = a.meta.user?.[sortBy]?.toLowerCase() || "";
        const valueB = b.meta.user?.[sortBy]?.toLowerCase() || "";
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      return 0;
    });

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("userId")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Id
                  </div>
                </th>
                <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Message
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("username")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Name
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((row: Logs, index: number) => {
                if (index >= startIndex && index < endIndex) {
                  return (
                    <tr key={index}>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.meta.user?.userId}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.message}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.meta.user?.username}
                        </p>
                      </td>
                      {/* Render other table data here */}
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" } rounded-md px-4 py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" } rounded-md px-4 py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default LogsTable;
