import { Spinner } from "@chakra-ui/react";

const Loader: React.FC = (props) => {
  return (
    <div className="flex h-screen items-center justify-center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        {...props}
      />
    </div>
  );
};

export default Loader;
