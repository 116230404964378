import Card from "components/card";
import {
  FullMenu as FullMenuRepsonse,
  UpdateMenuItems,
} from "interfaces/restaurants/menu";
import { FaPencil } from "react-icons/fa6";
import SetMenuModal from "./modals/AddSetMenuModal";
import { SetStateAction } from "react";

interface FullMenuProps {
  data: FullMenuRepsonse[];
  onEditSetMenu: (
    setMenu: UpdateMenuItems
  ) => SetStateAction<UpdateMenuItems> | void;
  setMenu?: UpdateMenuItems;
}

const SetMenu: React.FC<FullMenuProps> = ({ data, onEditSetMenu }) => {
  return (
    <>
      {data.map((setMenu, index) => (
        <div className="mb-4" key={index}>
          <Card extra={`w-full h-full sm:overflow-auto px-6  cursor-pointer `}>
            <header className="relative flex cursor-pointer flex-row items-center justify-between  pb-4 pt-4">
              <div className="w-full">
                <div>
                  <h1 className="font-bold">{setMenu.name}</h1>
                </div>
                <div className="flex flex-row justify-between pt-6">
                  <p className="text-sm">{setMenu.description}</p>
                  <p className="text-md font-bold text-[#7BC3E5]">
                    {setMenu.price}$
                  </p>
                </div>
              </div>
              <div className="mb-[3em]">
                <FaPencil
                  className="cursor-pointer"
                  onClick={() => onEditSetMenu(setMenu)}
                />
              </div>
            </header>
          </Card>
        </div>
      ))}
      <div className="flex justify-end">
        <SetMenuModal />
      </div>
    </>
  );
};

export default SetMenu;
