import { useToast } from "@chakra-ui/react";
import Card from "components/card";
import { useDeletePhotoMenu } from "hooks/menu/useDeleteMenuPhoto";
import { useUploadPhotoMenu } from "hooks/menu/useUploadPhotoMenu";
import { PhotoMenuResponse } from "interfaces/restaurants/menu";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaTrash } from "react-icons/fa";
import { LuUpload } from "react-icons/lu";
import { useParams } from "react-router-dom";

export interface PhotoMenuProps {
  data: PhotoMenuResponse;
}

export const PhotoMenu: React.FC<PhotoMenuProps> = ({ data }) => {
  const toast = useToast();
  const { _id } = useParams();
  const { mutate: uploadPhotoMenu } = useUploadPhotoMenu(String(_id));
  const { mutate: deleteImageMutation } = useDeletePhotoMenu();
  const [uploadedImages, setUploadedImages] = useState<
    { id?: string; name: string; preview: string }[]
  >([]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      formData.append("restaurantId", _id);

      try {
        const uploadedImage = {
          name: acceptedFiles[0].name,
          preview: URL.createObjectURL(acceptedFiles[0]),
        };
        setUploadedImages((prevImages) => [...prevImages, uploadedImage]);
      } catch (error) {
        console.error("Error uploading gallery photo:", error);
      }
    },
    [_id]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  const handleSaveChanges = async () => {
    try {
      const uploadPromises = uploadedImages.map(async (image) => {
        const formData = new FormData();
        const response = await fetch(image.preview);
        const blob = await response.blob();

        formData.append("file", blob, image.name);
        formData.append("restaurantId", _id);

        try {
          const result = uploadPhotoMenu(formData);
          return { success: true, result };
        } catch (error) {
          return { success: false, error };
        }
      });
      const results = await Promise.all(uploadPromises);
      const allUploadsSuccessful = results.every((result) => result.success);
      if (allUploadsSuccessful) {
        setUploadedImages([]);
        toast({
          title: "Photo Menus were uploaded successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        console.error("Some or all images failed to upload");
        toast({
          title: "Failed to upload Menu Photos!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error uploading menu photos:", error);
      toast({
        title: "Failed to upload Menu Photos!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const handleDeleteImage = (preview: string) => {
    setUploadedImages((prevImages) =>
      prevImages.filter((image) => image.preview !== preview)
    );
  };

  const handleDeleteImageById = async (id: string) => {
    try {
      deleteImageMutation({ restaurantId: _id, id });

      setUploadedImages((prevImages) =>
        prevImages.filter((image) => image.id !== id)
      );
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <>
      <Card extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}>
        <header
          className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div className="w-full pb-6 pt-6">
            {(uploadedImages.length > 0 ||
              (data?.media && data?.media.length > 0)) && (
              <div
                className={`grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5`}
              >
                {/* Dropzone */}
                <div
                  {...getRootProps()}
                  className={`mb-4 flex h-[12em] w-full flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center ${
                    uploadedImages.length > 0 ||
                    (data?.media && data?.media.length > 0)
                      ? "sm:w-[12.5em]"
                      : ""
                  }`}
                >
                  <input {...getInputProps()} />
                  <>
                    <LuUpload style={{ fontSize: "2em", color: "#979797" }} />
                    <p className="text-[16px] font-medium text-[#979797]">
                      Upload Files
                    </p>
                    <p className="text-[12px] text-[#979797]">
                      PNG and JPG are allowed
                    </p>
                  </>
                </div>
                {data?.media &&
                  data?.media.map((image) => (
                    <div key={image?.id} className="group relative">
                      <img
                        src={image?.path || ""}
                        alt="Existing"
                        className="mx-2 max-h-[12em] w-[12em]"
                      />
                      <FaTrash
                        className="text-black absolute right-0 top-1 cursor-pointer group-hover:text-red-900"
                        onClick={() => handleDeleteImageById(image.id)}
                      />
                    </div>
                  ))}
                {uploadedImages.map((image) => (
                  <div key={image.preview} className="group relative">
                    <img
                      src={image.preview}
                      alt="Uploaded"
                      className="mx-2 max-h-[12em] w-[12em]"
                    />
                    <FaTrash
                      className="text-black absolute right-0 top-1 cursor-pointer group-hover:text-red-900"
                      onClick={() => handleDeleteImage(image.preview)}
                    />
                  </div>
                ))}
              </div>
            )}
            {uploadedImages.length === 0 &&
              (!data?.media || data.media.length === 0) && (
                <div
                  {...getRootProps()}
                  className={`mb-4 flex h-[12em] w-full flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center`}
                >
                  <input {...getInputProps()} />
                  <>
                    <LuUpload style={{ fontSize: "2em", color: "#979797" }} />
                    <p className="text-[16px] font-medium text-[#979797]">
                      Upload Files
                    </p>
                    <p className="text-[12px] text-[#979797]">
                      PNG and JPG are allowed
                    </p>
                  </>
                </div>
              )}
          </div>
        </header>
      </Card>

      <div className="flex justify-end">
        <button
          onClick={handleSaveChanges}
          className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Save Changes
        </button>
      </div>
    </>
  );
};
