import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { Media } from "interfaces/restaurants/menu";
import {
  DeleteImagePayload,
  OpeningHoursPayload,
  RestaurantPayload,
  RestaurantResponse,
  UploadLogoResponse,
} from "interfaces/restaurants/restaurants";

export const getAllRestaurants = async () => {
  const { data } = await axiosInstance.get<RestaurantResponse[]>(
    `${BaseUrl.DEVELOPMENT}/restaurant/get-all-restaurants-with-images`
  );

  return data;
};

export const getRestaurantById = async (id: string) => {
  const { data } = await axiosInstance.get<RestaurantResponse>(
    `${BaseUrl.DEVELOPMENT}/restaurant/get-all-restaurants-by-id?id=${id}`
  );

  return data;
};

export const getRestaurantOwner = async () => {
  const { data } = await axiosInstance.get<RestaurantResponse[]>(
    `${BaseUrl.DEVELOPMENT}/restaurant/get-restaurant-owner`
  );

  return data;
};

export const createRestaurant = async (payload: RestaurantPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/restaurant/restaurants`,
    payload
  );

  return data;
};

export const updateRestaurant = async (payload: RestaurantPayload) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/restaurant/update-restaurant/${payload._id}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const updateRestaurantOwner = async (payload: RestaurantPayload) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/restaurant/${payload._id}/update-restaurant-owner`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const uploadLogo = async (formData: FormData) => {
  const { data } = await axiosInstance.post<UploadLogoResponse>(
    `${BaseUrl.DEVELOPMENT}/restaurant/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const deleteImage = async (payload: DeleteImagePayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/restaurant/delete-image`,
    payload
  );

  return data;
};

export const getGalleryPhotos = async (id: string) => {
  const { data } = await axiosInstance.get<Media[]>(
    `${BaseUrl.DEVELOPMENT}/restaurant/get-all-images-with-restaurants?id=${id}`
  );

  return data;
};

export const uploadGalleryPhotos = async (formData: FormData) => {
  const { data } = await axiosInstance.post<any>(
    `${BaseUrl.DEVELOPMENT}/restaurant/upload-restaurant-image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const addOpeningHours = async (
  id: string,
  openingHours: OpeningHoursPayload[]
) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/restaurant/${id}/add-opening-hours`,
    openingHours,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};
