import { useQuery } from "@tanstack/react-query";
import {
  FilteredRestaurantsPayload,
  getAllOwnerReservations,
} from "api/bookings/bookings";

export const useGetAllOwnerReservations = (
  payload: FilteredRestaurantsPayload
) => {
  return useQuery(["allOwnerBookings", payload], () =>
    getAllOwnerReservations(payload)
  );
};
