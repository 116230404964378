import {
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormikProvider, useFormik } from "formik";
import { useAddWaiter } from "hooks/staff/useAddWaiter";
import { useParams } from "react-router-dom";
import { Role } from "interfaces/auth/auth";

const AddWaiterModal: React.FC = () => {
  const { _id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useAddWaiter(String(_id));
  const genders = ["male", "female", "other"];

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      dateOfBirth: "",
      gender: "",
      roles: [],
    },
    onSubmit: (values) => {
      const dateOfBirthDate = new Date(values.dateOfBirth);

      dateOfBirthDate.setMinutes(
        dateOfBirthDate.getMinutes() - dateOfBirthDate.getTimezoneOffset()
      );

      const utcDateOfBirth = dateOfBirthDate.toISOString();
      mutate(
        {
          ...values,
          dateOfBirth: utcDateOfBirth,
          roles: [Role.STAFF],
        },
        {
          onSuccess: () => {
            formik.resetForm({
              values: {
                password: "",
                email: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                dateOfBirth: "",
                gender: "",
                roles: [],
              },
            });
            onClose();
          },
          onError: () => {
            formik.resetForm({
              values,
            });
          },
        }
      );
    },
  });

  const isFormInvalid =
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.gender &&
    formik.isValid &&
    formik.values.dateOfBirth &&
    formik.values.phoneNumber &&
    formik.values.password &&
    formik.values.email;

  return (
    <>
      <button
        onClick={onOpen}
        className="font-base mt-4 w-[9em] rounded-xl bg-brand-500 py-[8px] text-sm text-white 
                    dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        Add Waiter
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Waiter</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="w-full">
                  <InputField
                    required
                    {...formik.getFieldProps("firstName")}
                    variant="auth"
                    extra="mb-3"
                    label="First Name*"
                    placeholder="Enter your First Name"
                    id="firstName"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    required
                    {...formik.getFieldProps("lastName")}
                    variant="auth"
                    extra="mb-3"
                    label="Last Name*"
                    placeholder="Enter your Last Name"
                    id="lastName"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    required
                    {...formik.getFieldProps("email")}
                    variant="auth"
                    extra="mb-3"
                    label="Email*"
                    placeholder="Enter your Email"
                    id="email"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <div className="flex flex-col">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Gender*
                    </FormLabel>
                    <Select
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                      }}
                      className="mb-3 flex  items-center justify-center  border bg-white/0  outline-none"
                      {...formik.getFieldProps("gender")}
                      variant="auth"
                      placeholder="Enter Gender"
                      id="gender"
                    >
                      {genders.map((gender) => (
                        <>
                          <option key={gender} value={gender}>
                            {gender.charAt(0).toUpperCase() + gender.slice(1)}
                          </option>
                        </>
                      ))}
                    </Select>
                  </div>
                  <div className="flex flex-col">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Date of Birth*
                    </FormLabel>
                    <Input
                      {...formik.getFieldProps("dateOfBirth")}
                      value={formik.values.dateOfBirth}
                      sx={{
                        borderRadius: "0.75rem",
                        height: "3.5em",
                        fontSize: "14px",
                        marginBottom: "1em",
                      }}
                      placeholder="Select Date and Time"
                      size="md"
                      type="date"
                    />
                  </div>
                  <div className="mb-2">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                        paddingLeft: "0.3em",
                      }}
                    >
                      Phone Number*
                    </FormLabel>
                    <PhoneInput
                      inputStyle={{
                        marginTop: "0.5rem",
                        display: "flex",
                        height: "3rem",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "0.75rem",
                        border: "1px solid #eceaea",
                        backgroundColor: "rgba(255, 255, 255, 0)",
                        padding: "0.75rem",
                        paddingLeft: "4em",
                        fontSize: "0.875rem",
                        outline: "none",
                      }}
                      country={"us"}
                      {...formik.getFieldProps("phoneNumber")}
                      value={formik.values.phoneNumber}
                      placeholder="Enter Last Name"
                      onChange={(value) =>
                        formik.setFieldValue("phoneNumber", value)
                      }
                    />
                  </div>
                  <InputField
                    {...formik.getFieldProps("password")}
                    variant="auth"
                    extra="mb-3"
                    label="Password*"
                    placeholder="Enter Password"
                    id="password"
                    type="password"
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="pl-4" />
                <button
                  className={`mt-2 w-[10em] rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
                  disabled={!isFormInvalid}
                >
                  {isLoading ? <Spinner /> : "Add Waiter"}
                </button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormikProvider>
      </Modal>
    </>
  );
};

export default AddWaiterModal;
