import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import SignUpLayout from "layouts/auth/signUp";
import { Suspense, useContext, useEffect, useState } from "react";
import { TokenContext } from "contexts/AuthContext";
import { useToken } from "hooks/api/useToken";
import ForgotPasswordLayout from "layouts/auth/forgotPassword";
import ConfirmEmailLayout from "layouts/auth/confirmEmail";
import ResetPasswordLayout from "layouts/auth/resetPassword";
import Profile from "layouts/admin/profile";
import Loader from "components/loader/Loader";
import AddRestaurantsView from "views/admin/restaurants/addRestaurant";
import BasicInformationView from "views/admin/restaurants/basicInfo";
import EditBasicInformationView from "views/admin/restaurants/editBasicInfo";
import AddAveragePriceView from "views/admin/restaurants/averagePrice";
import OpeningHoursView from "views/admin/restaurants/openingHours";
import GalleryPhotoView from "views/admin/restaurants/galleryPhoto";
import CustomizeDetailsView from "views/admin/restaurants/customizeDetails";
import MenuView from "views/admin/restaurants/menu";
import FullMenuView from "views/admin/restaurants/fullMenu";
import SetMenuView from "views/admin/restaurants/setMenu";
import PhotoMenuView from "views/admin/restaurants/photoMenu";
import UrlMenuView from "views/admin/restaurants/urlMenu";
import ViewAdminRestaurantView from "views/admin/restaurants/viewAdminRestaurant";
import SettingsView from "views/admin/settings";
import TermsView from "views/terms";
import OffersView from "views/admin/offers";
import AllOffersView from "views/admin/offers/allOffers";
import BookingsView from "views/admin/bookings";
import WaitersView from "views/admin/restaurants/waiters";

export const Router: React.FC = () => {
  const { token } = useContext(TokenContext);
  const { data: response, error } = useToken();

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (response || error) {
      setUserLoaded(true);
    }
  }, [response, error]);

  const isAuthenticated = token.access_token;

  if (!userLoaded) {
    return <Loader />;
  }
  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Routes>
          {!isAuthenticated && (
            <>
              <Route path="auth/login" element={<AuthLayout />} />
              <Route path="auth/sign-up" element={<SignUpLayout />} />
              <Route
                path="auth/forgot-password"
                element={<ForgotPasswordLayout />}
              />
              <Route path="/recovery/:code" element={<ResetPasswordLayout />} />
              <Route
                path="/verification/:code"
                element={<ConfirmEmailLayout />}
              />
              {/* Redirect from admin/default to auth/login if not authenticated */}
              <Route
                path="admin/*"
                element={<Navigate to="/auth/login" replace />}
              />
            </>
          )}
          {isAuthenticated && (
            <>
              <Route path="admin/*" element={<AdminLayout />} />
              <Route path="/" element={<Navigate to="/admin" replace />} />
              <Route path="admin/profile" element={<Profile />} />
              <Route
                path="admin/restaurant/add/:_id"
                element={<AddRestaurantsView />}
              />
              <Route
                path="admin/restaurant/basic-information"
                element={<BasicInformationView />}
              />
              <Route
                path="admin/restaurant/basic-information/:_id"
                element={<EditBasicInformationView />}
              />
              <Route
                path="admin/restaurant/view/:_id"
                element={<ViewAdminRestaurantView />}
              />
              <Route path="admin/waiters/:_id" element={<WaitersView />} />
              <Route
                path="admin/restaurant/average-price/:_id"
                element={<AddAveragePriceView />}
              />
              <Route
                path="admin/restaurant/opening-hours/:_id"
                element={<OpeningHoursView />}
              />
              <Route
                path="admin/restaurant/gallery-photo/:_id"
                element={<GalleryPhotoView />}
              />
              <Route
                path="admin/restaurant/customize-details/:_id"
                element={<CustomizeDetailsView />}
              />
              <Route path="admin/restaurant/menu/:_id" element={<MenuView />} />
              <Route
                path="admin/restaurant/full-menu/:_id"
                element={<FullMenuView />}
              />
              <Route
                path="admin/restaurant/set-menu/:_id"
                element={<SetMenuView />}
              />
              <Route
                path="admin/restaurant/photo-menu/:_id"
                element={<PhotoMenuView />}
              />
              <Route
                path="admin/restaurant/url-menu/:_id"
                element={<UrlMenuView />}
              />
              <Route path="admin/settings/:_id" element={<SettingsView />} />
              <Route path="admin/terms-of-use" element={<TermsView />} />
              <Route path="admin/offers/:_id" element={<OffersView />} />
              <Route path="admin/all-offers/:_id" element={<AllOffersView />} />
              <Route path="admin/bookings/:_id" element={<BookingsView />} />
              {/* Redirect from auth pages to admin if authenticated */}
              <Route path="auth/*" element={<Navigate to="/admin" replace />} />
            </>
          )}
          {/* Fallback for any other routes */}
          <Route
            path="*"
            element={
              <Navigate
                to={isAuthenticated ? "admin/default" : "/auth/login"}
                replace
              />
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
