import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateMenuItem } from "api/restaurants/menu";
import { UpdateUserObject } from "interfaces/restaurants/menu";

export const useUpdateMenuItem = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((object: UpdateUserObject) => updateMenuItem(object), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Menu Item was updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["fullMenu"]);
    },
    onError: () => {
      toast({
        title: "Menu Item failed to update!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
