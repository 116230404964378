import { createContext, useContext, useState } from "react";

interface AppProviderProps {
  children: React.ReactNode;
}

type LogoutModalContextType = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const LogoutModalContext = createContext<LogoutModalContextType>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const useLogoutModal = () => useContext(LogoutModalContext);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <LogoutModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
    </LogoutModalContext.Provider>
  );
};
