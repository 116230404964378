import Loader from "components/loader/Loader";
import { useGetGlobals } from "hooks/globals/useGetGlobals";
import Settings from "./components/Settings";
import AddCategorySettingModal from "./components/AddCategorySetting";

export default function SettingsView() {
  const { data, isLoading } = useGetGlobals();

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        {data && data.length > 0 ? (
          <>
            <Settings data={data} />
          </>
        ) : isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col items-center justify-center pt-[15em]">
            <p>There are no categories, click the button to add.</p>
            <AddCategorySettingModal />
          </div>
        )}
      </div>
    </div>
  );
}
