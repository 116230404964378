import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { UpdateMenuItems } from "interfaces/restaurants/menu";
import { useUpdateMenuItem } from "hooks/menu/useUpdateMenuItem";
import { useEffect } from "react";

interface EditMenuItemProps {
  menuItem: UpdateMenuItems | null;
  opened: boolean;
  onClose: () => void;
}

const EditMenuItemModal: React.FC<EditMenuItemProps> = ({
  menuItem,
  onClose,
  opened,
}) => {
  const { mutate } = useUpdateMenuItem();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: menuItem?.name || "",
      description: menuItem?.description || "",
      price: menuItem?.price || null,
    },
    onSubmit: (values) => {
      mutate(
        {
          id: String(menuItem?._id),
          payload: { ...values },
        },
        {
          onSuccess: () => {
            handleClose();
            formik.resetForm({
              values,
            });
          },
          onError: () => {
            formik.resetForm({
              values,
            });
          },
        }
      );
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        name: menuItem?.name || "",
        description: menuItem?.description || "",
        price: menuItem?.price || null,
      },
    });
    // eslint-disable-next-line
  }, [menuItem]);

  return (
    <>
      <Modal isOpen={opened} onClose={handleClose}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Edit Menu Item</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="w-full">
                  <InputField
                    {...formik.getFieldProps("name")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Name"
                    placeholder="Enter your Item Name"
                    id="name"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("description")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Description (optional)"
                    placeholder="Enter your Item Description"
                    id="description"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("price")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Price "
                    placeholder="Enter your Price"
                    id="price"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="pl-4" />
                <button className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white">
                  Save Changes
                </button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormikProvider>
      </Modal>
    </>
  );
};

export default EditMenuItemModal;
