import { useNavigate } from "react-router-dom";
import { ForgotPasswordForm } from "./components/ForgotPasswordForm";

export default function ForgotPassword() {
  const navigate = useNavigate();

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Trouble with logging in?
        </h4>
        <p className="mb-9 ml-1 justify-center text-base">
          Enter your username or email adress and we’ll send you the link to get
          back to your account.
        </p>
        <ForgotPasswordForm />
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Go back to
          </span>
          <a
            onClick={() => navigate("/auth/login")}
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}
