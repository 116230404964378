import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { login } from "api/auth/auth";
import { TokenContext } from "contexts/AuthContext";
import { LoginPayload } from "interfaces/auth/auth";
import { useContext } from "react";

export const useLogin = () => {
  const toast = useToast();
  const { setToken } = useContext(TokenContext);
  return useMutation((payload: LoginPayload) => login(payload), {
    onSuccess: (data) => {
      localStorage.setItem("authToken", data.access_token);
      localStorage.setItem("access_token_expiration", data.refresh_token);
      setToken(data);
      toast({
        title: "Successfully Logged In",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Failed to Log In",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
