import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addOpeningHours } from "api/restaurants/restaurants";

export const useAddOpeningHours = (id: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: any) => addOpeningHours(id, payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Opening Hours were added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["restaurants", id]);
    },
    onError: () => {
      toast({
        title: "Opening Hours failed to be added!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
