import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Table } from "@chakra-ui/react";
import { FaRegTrashCan } from "react-icons/fa6";
import { DeleteOffer, OffersResponse } from "interfaces/offers/offers";

type RowObj = {
  _id: string;
  date: string;
  time: string;
  hour: string;
  menuType: string;
  status: string;
  title: string;
  fromDate: string;
  toTime: string;
  fromTime: string;
  toDate: string;
  percentage: number;
  isActive: boolean;
};

function AllOffersTable(props: {
  tableData?: any;
  offer?: DeleteOffer;
  onDeleteOffer: (offer: OffersResponse) => void;
  refetch?: () => void;
}) {
  const { tableData, onDeleteOffer } = props;

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Handle data updates if needed
  }, [props.tableData]);

  const columnNames = [
    "Title",
    "Percentage",
    "Menu",
    "Date",
    "Hour",
    "Status",
    "Delete",
  ];

  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                {columnNames?.map((columnName, index) => (
                  <th
                    key={index}
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    <div className="items-center justify-between text-sm text-[#a3aed0]">
                      {columnName}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row: RowObj, index: number) => {
                if (index >= startIndex && index < endIndex) {
                  return (
                    <tr key={index}>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.title}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.percentage}% Off
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.menuType === "FULL_MENU"
                            ? "Full Menu"
                            : "Set Menu"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {formatDate(row.fromDate)} - {formatDate(row.toDate)}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.fromTime} - {row.toTime}
                        </p>
                      </td>
                      <td
                        className={`min-w-[150px] border-white/0 py-3  pr-4 ${
                          row.status === "ACTIVE"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.status === "ACTIVE" ? "Active" : "Non Active"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <FaRegTrashCan
                          onClick={() => onDeleteOffer(row)}
                          className="text-black h-4 w-4 cursor-pointer font-bold dark:text-white"
                        />
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default AllOffersTable;
