import { useContext, useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Table } from "@chakra-ui/react";
import { ReservationStatus } from "api/bookings/bookings";
import { IoCheckmarkSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { AuthContext } from "contexts/AuthContext";
import { Role } from "interfaces/auth/auth";
import { FiSearch } from "react-icons/fi";

export interface RowObj {
  _id: string;
  reservedBy?: {
    firstName: string;
    email: string;
    lastName: string;
  };
  reservationDateTime?: string;
  time?: string;
  menu?: string;
  status: ReservationStatus;
  title?: string;
  hasArrived?: boolean;
  guestsNumber?: string;
  isActive?: boolean;
  [key: string]: any;
}

function BookingsTable(props: {
  tableData?: any;
  booking?: RowObj;
  onConfirmBooking: (booking: RowObj) => void;
  refetch?: () => void;
}) {
  const { tableData, onConfirmBooking } = props;

  const [user] = useContext(AuthContext);
  const itemsPerPage = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (columnName: string) => {
    const columnToSortBy: {
      [key: string]:
        | "reservationDateTime"
        | "time"
        | "guestsNumber"
        | "status"
        | "reservedBy.firstName"
        | "reservedBy.lastName"
        | null;
    } = {
      Name: "reservedBy.firstName",
      Date: "reservationDateTime",
      Time: "time",
      Guests: "guestsNumber",
      Status: "status",
      "Has Arrived": null,
      Confirm: null,
      Reject: null,
    };

    const sortByColumn = columnToSortBy[columnName];

    if (sortByColumn) {
      if (sortBy === sortByColumn) {
        if (sortOrder === "asc") {
          setSortOrder("desc");
        } else if (sortOrder === "desc") {
          setSortBy(null);
          setSortOrder("asc");
        }
      } else {
        setSortBy(sortByColumn);
        setSortOrder("asc");
      }
    }
  };

  const sortedData = tableData
    ?.filter((row: RowObj) => {
      const fullName =
        `${row.reservedBy.firstName} ${row.reservedBy.lastName}`.toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();
      return fullName.includes(searchTermLower);
    })
    ?.sort((a: RowObj, b: RowObj) => {
      if (sortBy) {
        switch (sortBy) {
          case "reservedBy.firstName":
          case "reservedBy.lastName":
            const fullNameA =
              `${a.reservedBy.firstName} ${a.reservedBy.lastName}`.toLowerCase();
            const fullNameB =
              `${b.reservedBy.firstName} ${b.reservedBy.lastName}`.toLowerCase();
            return sortOrder === "asc"
              ? fullNameA.localeCompare(fullNameB)
              : fullNameB.localeCompare(fullNameA);
          case "reservationDateTime":
            return sortOrder === "asc"
              ? new Date(a.reservationDateTime).getTime() -
                  new Date(b.reservationDateTime).getTime()
              : new Date(b.reservationDateTime).getTime() -
                  new Date(a.reservationDateTime).getTime();
          case "time":
            return sortOrder === "asc"
              ? (a.time ?? "").localeCompare(b.time ?? "")
              : (b.time ?? "").localeCompare(a.time ?? "");
          case "guestsNumber":
            return sortOrder === "asc"
              ? parseInt(a.guestsNumber) - parseInt(b.guestsNumber)
              : parseInt(b.guestsNumber) - parseInt(a.guestsNumber);

          default:
            return 0;
        }
      }
      return 0;
    });

  const isPlaceOwner = user?.roles.includes(Role.PLACE_OWNER);

  useEffect(() => {
    // Handle data updates if needed
  }, [props.tableData]);

  const columnNames = [
    "Name",
    "Date",
    "Time",
    "Guests",
    "Status",
    "Has Arrived",
    "Confirm",
    "Reject",
  ];

  function getStatusDisplay(status: ReservationStatus): string {
    switch (status) {
      case ReservationStatus.INITIALLY_RESERVED:
        return "Initialy Reserved";
      case ReservationStatus.RESERVED:
        return "Reserved";
      case ReservationStatus.CANCELLED:
        return "Canceled";
      case ReservationStatus.REJECTED:
        return "Rejected";
      default:
        return "Unknown";
    }
  }

  const filteredColumnNames = !isPlaceOwner
    ? columnNames.filter((name) => name !== "Confirm" && name !== "Reject")
    : columnNames;

  return (
    <>
      <div className="mb-5 flex h-[40px]  items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <div className="mt-8 overflow-x-scroll xl:overflow-auto">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                {filteredColumnNames?.map((columnName, index) => (
                  <>
                    <th
                      key={index}
                      onClick={() => handleSort(columnName)}
                      className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                    >
                      <div className="items-center justify-between text-sm text-[#a3aed0]">
                        {columnName}
                      </div>
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData
                ?.slice(startIndex, endIndex)
                .map((row: RowObj, index: number) => (
                  <tr key={index}>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.reservedBy.firstName} {row.reservedBy.lastName}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.reservationDateTime.slice(0, 10)}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.reservationDateTime.slice(11, 16)}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.guestsNumber}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {getStatusDisplay(row.status)}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.hasArrived ? "Yes" : "No"}
                      </p>
                    </td>
                    {isPlaceOwner && (
                      <>
                        <td className="min-w-[150px] border-white/0 py-3  pr-4">
                          {row.status === ReservationStatus.REJECTED ? (
                            <IoCheckmarkSharp
                              onClick={() => onConfirmBooking(row)}
                              className="text-black h-4 w-4 cursor-pointer font-bold dark:text-white"
                            />
                          ) : (
                            <IoCheckmarkSharp className="h-4 w-4 cursor-pointer font-bold text-gray-500 dark:text-white" />
                          )}
                        </td>
                        <td className="min-w-[150px] border-white/0 py-3 pr-4">
                          {row.status ===
                            ReservationStatus.INITIALLY_RESERVED ||
                          row.status === ReservationStatus.RESERVED ? (
                            <IoMdClose
                              onClick={() => onConfirmBooking(row)}
                              className="text-black h-4 w-4 cursor-pointer font-bold dark:text-white"
                            />
                          ) : (
                            <IoMdClose className="h-4 w-4 cursor-pointer font-bold text-gray-500 dark:text-white" />
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default BookingsTable;
