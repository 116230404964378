import BookingsTable, { RowObj } from "./components/BookingsTable";
import { FilteredRestaurantsPayload } from "api/bookings/bookings";
import { useGetAllReservations } from "hooks/bookings/useGetAllReservations";
import { useState } from "react";
import ConfirmBookingModal from "./components/ConfirmBookinModal";
import CancelBookingModal from "./components/CancelBookingModal";

export default function AllBookingsView() {
  const filters: FilteredRestaurantsPayload = {
    limit: 10000,
    page: 0,
    filter: [],
    sort: [],
  };
  const { data, refetch } = useGetAllReservations(filters);
  const [selectedBooking, setSelectedBooking] = useState<RowObj>();
  const [isConfrimModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleConfirmBooking = (booking: RowObj) => {
    setSelectedBooking(booking);
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        <div className="h-full">
          <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
            <BookingsTable
              refetch={refetch}
              onConfirmBooking={handleConfirmBooking}
              tableData={data}
            />
            {selectedBooking && (
              <ConfirmBookingModal
                booking={selectedBooking}
                opened={isConfrimModalOpen}
                onClose={() => setIsConfirmModalOpen(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
