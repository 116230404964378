import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { RowObj } from "./BookingsTable";
import { useConfirmReservation } from "hooks/bookings/useConfrimReservation";
import { ReservationStatus } from "api/bookings/bookings";

export interface Props {
  booking: RowObj | null;
  opened: boolean;
  onClose: () => void;
}

const ConfirmBookingModal: React.FC<Props> = ({ booking, opened, onClose }) => {
  const { mutate } = useConfirmReservation();
  const toast = useToast();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (booking) {
      const newStatus =
        booking.status === ReservationStatus.RESERVED
          ? ReservationStatus.REJECTED
          : booking.status === ReservationStatus.INITIALLY_RESERVED
          ? ReservationStatus.REJECTED
          : ReservationStatus.RESERVED;

      mutate(
        {
          _id: booking._id,
          status: newStatus,
        },
        {
          onSuccess: () => {
            handleClose();
            const toastMessage =
              newStatus === ReservationStatus.RESERVED
                ? "Reservation was confirmed successfully"
                : "Reservation was rejected successfully";
            toast({
              title: toastMessage,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          },
          onError: () => {
            const errorMessage = "Reservation failed to be confirmed!";
            toast({
              title: errorMessage,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        }
      );
    }
  };

  const buttonText =
    booking &&
    (booking.status === ReservationStatus.RESERVED ||
      booking.status === ReservationStatus.INITIALLY_RESERVED)
      ? "Reject Booking"
      : "Confirm Booking";

  return (
    <>
      <Modal isOpen={opened} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {booking && booking.status === ReservationStatus.RESERVED
              ? "Reject Booking"
              : "Confirm Booking"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1>
              {booking && booking.status === ReservationStatus.RESERVED
                ? "Are you sure you want to reject this booking?"
                : "Are you sure you want to confirm this booking?"}
            </h1>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              Close
            </Button>
            <div className="pl-4" />
            <button
              onClick={handleSubmit}
              className="mt-2 w-[10em] rounded-xl bg-blue-500 py-[12px] text-base font-medium text-white"
            >
              {buttonText}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmBookingModal;
