import Card from "components/card";
import { Switch as ChakraSwitch, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import {
  OpeningHoursPayload,
  RestaurantResponse,
} from "interfaces/restaurants/restaurants";
import { useState } from "react";
import { useAddOpeningHours } from "hooks/restaurants/useAddOpeningHours";

interface OpeningHoursProps {
  data: RestaurantResponse;
}

export const OpeningHours: React.FC<OpeningHoursProps> = ({ data }) => {
  const toast = useToast();
  const { _id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<OpeningHoursPayload[]>(() => {
    const defaultFormData: OpeningHoursPayload[] = [
      { day: "Monday", startTime: "", endTime: "", workDay: true },
      { day: "Tuesday", startTime: "", endTime: "", workDay: true },
      { day: "Wednesday", startTime: "", endTime: "", workDay: true },
      { day: "Thursday", startTime: "", endTime: "", workDay: true },
      { day: "Friday", startTime: "", endTime: "", workDay: true },
      { day: "Saturday", startTime: "", endTime: "", workDay: false },
      { day: "Sunday", startTime: "", endTime: "", workDay: false },
    ];

    if (data.openingHours && data.openingHours.length > 0) {
      return defaultFormData.map((defaultDay) => {
        const existingDay = data.openingHours.find(
          (existingDay) => existingDay.day === defaultDay.day
        );
        return existingDay || defaultDay;
      });
    }

    return defaultFormData;
  });

  const { mutate } = useAddOpeningHours(String(_id));

  const handleSwitchChange = (index: number) => {
    const newFormData = [...formData];
    if (newFormData[index].workDay) {
      newFormData[index] = {
        ...newFormData[index],
        workDay: false,
        startTime: "",
        endTime: "",
      };
    } else {
      newFormData[index] = {
        ...newFormData[index],
        workDay: !newFormData[index].workDay,
      };
    }
    setFormData(newFormData);
  };

  const handleTimeChange = (
    index: number,
    type: "start" | "end",
    value: string
  ) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [type === "start" ? "startTime" : "endTime"]: value,
    };
    setFormData(newFormData);
  };

  const handleSaveChanges = async () => {
    const isValid = formData
      .filter(
        (item) => item.workDay && item.startTime !== "" && item.endTime !== ""
      )
      .every((item) => item.startTime < item.endTime);

    if (!isValid) {
      toast({
        title: "Start time must be lower than end time for workdays!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    mutate(formData);
    navigate(`/admin/restaurant/add/${_id}`);
  };

  return (
    <>
      <Card extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}>
        <header
          className={` relative flex cursor-pointer flex-row items-center justify-between pt-4`}
        >
          <div className="w-full">
            <h1 className={`pb-4 text-lg font-bold`}>
              Tell guests when you are open
            </h1>
            {formData.map((item, index) => (
              <div
                className={`flex w-[60%] flex-row items-center pb-2`}
                key={index}
              >
                <div className="flex w-full flex-row items-center gap-4 ">
                  <ChakraSwitch
                    isChecked={item.workDay}
                    onChange={() => handleSwitchChange(index)}
                  />
                  <p className={`text-sm font-medium`}>{item.day}</p>
                </div>
                <div className="relative flex flex-row gap-4">
                  <input className="hidden" type="time" />
                  <input
                    type="time"
                    id={`start-${index}`}
                    name={`start-${index}`}
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="From"
                    value={item.startTime}
                    onChange={(e) =>
                      handleTimeChange(index, "start", e.target.value)
                    }
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[10em] text-right text-sm"
                  />
                  <input
                    type="time"
                    id={`end-${index}`}
                    name={`end-${index}`}
                    min="05:00"
                    max="23:00"
                    required
                    placeholder="To"
                    value={item.endTime}
                    onChange={(e) =>
                      handleTimeChange(index, "end", e.target.value)
                    }
                    className="mt-2 flex h-12 items-center justify-center rounded-xl border bg-white/0 p-3 pl-[10em] text-right text-sm"
                  />
                </div>
              </div>
            ))}
          </div>
        </header>
      </Card>
      <div className="flex justify-end">
        <button
          onClick={handleSaveChanges}
          className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Save Changes
        </button>
      </div>
    </>
  );
};
