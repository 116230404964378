import { FormLabel } from "@chakra-ui/react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import Map from "components/map/Map";
import { FormikProvider, useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { LuUpload } from "react-icons/lu";
import { useUpdateRestaurant } from "hooks/restaurants/useUpdateRestaurant";
import { RestaurantResponse } from "interfaces/restaurants/restaurants";
import { useGetGalleryPhotos } from "hooks/restaurants/useGetGalleryPhotos";
import { useGetUrlMenu } from "hooks/menu/useGetUrlMenu";
import { useGetFullMenu } from "hooks/menu/useGetFullMenu";
import { useGetSetMenu } from "hooks/menu/useGetSetMenu";
import { useGetMenuPhotos } from "hooks/menu/useGetPhotoMenu";
import { AuthContext } from "contexts/AuthContext";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
});

interface ViewAdminRestaurantProps {
  restaurant: RestaurantResponse;
}

export const ViewAdminRestaurant: React.FC<ViewAdminRestaurantProps> = ({
  restaurant,
}) => {
  const [uploadedImageUrl] = useState<string | null>(null);
  const [user] = useContext(AuthContext);
  const { mutate } = useUpdateRestaurant();
  const { data: galleryPhoto } = useGetGalleryPhotos(String(restaurant._id));
  const { data: urlMenu } = useGetUrlMenu(String(restaurant._id));
  const { data: fullMenu } = useGetFullMenu(String(restaurant._id));
  const { data: setMenu } = useGetSetMenu(String(restaurant._id));
  const { data: photoMenu } = useGetMenuPhotos(String(restaurant._id));
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({
    latitude: 42.6629,
    longitude: 21.1655,
  });

  const handleApprove = () => {
    mutate({ _id: restaurant._id, isVerifiedStatus: "APPROVED" });
    navigate(`/admin/restaurant`);
  };

  const handleDecline = () => {
    mutate({ _id: restaurant._id, isVerifiedStatus: "DECLINED" });
    navigate(`/admin/restaurant`);
  };

  console.log("res", restaurant.isVerifiedStatus);

  const formik = useFormik({
    initialValues: {
      name: restaurant.name || "",
      email: restaurant.email || "",
      website: restaurant.website || "",
      logo: restaurant.logo || null,
      aboutUs: restaurant.aboutUs || "",
      phoneNumber: restaurant.phoneNumber || "",
      openingHours: [],
      street: restaurant.street || "",
      state: restaurant.state || "",
      city: restaurant.city || "",
      userId: user,
      zip: restaurant.zip || "",
      socialMedia: {
        facebook:
          (restaurant.socialMedia && restaurant.socialMedia.facebook) || "",
        insta: (restaurant.socialMedia && restaurant.socialMedia.insta) || "",
        twitter:
          (restaurant.socialMedia && restaurant.socialMedia.twitter) || "",
        tiktok: (restaurant.socialMedia && restaurant.socialMedia.tiktok) || "",
      },

      location: {
        type: "Point",
        coordinates: (restaurant.location &&
          restaurant.location.coordinates) || [42.6629, 21.1655],
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      mutate(
        {
          logo: null,
          ...values,
        },
        {
          onSuccess: (data) => {
            formik.resetForm();
            navigate(`/admin/restaurant/add/${data._id}`);
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const handleBlur = async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${formik.values.street}, ${formik.values.city}, ${formik.values.state}, ${formik.values.zip}.json?access_token=pk.eyJ1Ijoiam9uYmFsYWEiLCJhIjoiY2xydXBvYmU0MGYzNTJsbXJpdW94dTg2aSJ9.-oPQP7bEx5uTemsneAd7YQ`
    );
    const data = await response.json();

    const [longitude, latitude] = data.features[0].center;

    formik.setFieldValue("location", {
      type: "Point",
      coordinates: [latitude, longitude],
    });
    setCoordinates({ latitude: latitude, longitude: longitude });
  };

  useEffect(() => {
    handleBlur();
  }, []);

  return (
    <>
      <div className="flex gap-4 pb-4">
        {restaurant.isVerifiedStatus === "APPROVED" && (
          <button
            onClick={handleDecline}
            className="font-small mt-4 w-[8em] rounded-xl bg-[#C64343] py-[10px] text-sm text-white 
                  dark:bg-[#C64343] dark:text-white dark:hover:bg-[#C64343] dark:active:bg-[#C64343]"
          >
            Decline
          </button>
        )}
        {restaurant.isVerifiedStatus === "DECLINED" && (
          <button
            onClick={handleApprove}
            className="font-small mt-4 w-[8em] rounded-xl bg-brand-500 py-[10px] text-sm text-white 
                  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Approve
          </button>
        )}
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col  lg:flex-row">
              {/* Information */}
              <div className="w-full  lg:w-[50%]">
                <h1 className=" pb-2 pt-6 text-xl font-bold text-navy-700 dark:text-white">
                  Restaurant Details
                </h1>
                <p className="pb-4 text-[13px] text-gray-500">
                  Add restaurant information (Contact Information, Restaurant
                  Description, Business Hours, Typical Check per Guest, Payment
                  Options, Directions, Parking Info and Social Media Sites)
                </p>
                <div className="w-full">
                  <div className="flex w-full flex-col justify-between gap-4 lg:flex-row">
                    <InputField
                      disabled
                      {...formik.getFieldProps("name")}
                      variant="auth"
                      extra="mb-3 lg:w-[50%]"
                      label="Restaurant Name*"
                      placeholder="Enter your Restaurant Name"
                      id="name"
                      type="text"
                      onChange={formik.handleChange}
                    />
                    <InputField
                      disabled
                      {...formik.getFieldProps("email")}
                      variant="auth"
                      extra="mb-3 lg:w-[50%]"
                      label="Email"
                      placeholder="Enter your Email"
                      id="email"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-4">
                  <div className="flex w-full flex-col lg:flex-row ">
                    <div className="flex w-full justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("city")}
                        variant="auth"
                        extra="mb-3 lg:w-[50%]"
                        label="Adress*"
                        placeholder="City"
                        id="city"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("state")}
                        variant="auth"
                        extra="mb-3 lg:w-[50%]"
                        placeholder="State"
                        id="state"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full flex-row justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("street")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Street address"
                        id="streetAddress"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("zip")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Zip Code"
                        id="zip"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="flex w-full flex-row justify-between gap-4">
                    <InputField
                      disabled
                      {...formik.getFieldProps("aboutUs")}
                      style={{ height: "5em" }}
                      variant="auth"
                      extra="mb-3 w-[100%] "
                      label="About the Restaurant"
                      placeholder="A brief informative section the provides users with an overview of the restaurant"
                      id="aboutUs"
                      type="text"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col lg:flex-row">
                  <div className="flex flex-col lg:w-[50%]">
                    <div className="w-full">
                      <InputField
                        disabled
                        {...formik.getFieldProps("website")}
                        variant="auth"
                        extra="mb-3 w-[100%] "
                        label="Website"
                        placeholder="The webiste URL of the restaurant"
                        id="website"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-5 w-full ">
                      <FormLabel
                        style={{
                          fontSize: "14px",
                          paddingLeft: "0.3em",
                        }}
                      >
                        Phone Number*
                      </FormLabel>
                      <PhoneInput
                        disabled
                        {...formik.getFieldProps("phoneNumber")}
                        value={formik.values.phoneNumber}
                        disableDropdown
                        inputStyle={{
                          marginTop: "0.5rem",
                          marginBottom: "5px",
                          display: "flex",
                          height: "3rem",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "0.75rem",
                          border: "1px solid #eceaea",
                          backgroundColor: "rgb(243 244 246)",
                          padding: "0.75rem",
                          paddingLeft: "4em",
                          fontSize: "0.875rem",
                          outline: "none",
                          opacity: "0.5",
                        }}
                        country={"us"}
                        placeholder="Enter Last Name"
                        onChange={(value) =>
                          formik.setFieldValue("phoneNumber", value)
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[50%] pl-4">
                    <FormLabel
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Logo
                    </FormLabel>
                    <div className="flex h-[9em] flex-col items-center justify-center border-[1.5px] border-dashed bg-white/0 p-4 text-center">
                      {!uploadedImageUrl && !formik.values.logo && (
                        <>
                          <LuUpload
                            style={{ fontSize: "2em", color: "#979797" }}
                          />
                          <p className="text-[16px] font-medium text-[#979797]">
                            Upload Files
                          </p>
                          <p className="text-[12px] text-[#979797]">
                            PNG and JPG are allowed
                          </p>
                        </>
                      )}
                      {(uploadedImageUrl ||
                        (formik.values.logo && formik.values.logo.path)) && (
                        <img
                          src={
                            uploadedImageUrl ||
                            (formik.values.logo && formik.values.logo.path)
                          }
                          alt="Uploaded Logo"
                          className="h-[10em] max-w-full"
                          style={{ width: "60%", height: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-4">
                  <div className="flex w-full flex-col lg:flex-row ">
                    <div className="flex w-full justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.insta")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        label="Social Media"
                        placeholder="Instagram URL"
                        id="insta"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.facebook")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Facebook URL"
                        id="facebook"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex w-full flex-row justify-between gap-2">
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.twitter")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Twitter URL"
                        id="twitter"
                        type="text"
                        onChange={formik.handleChange}
                      />
                      <InputField
                        disabled
                        {...formik.getFieldProps("socialMedia.tiktok")}
                        variant="auth"
                        extra="mb-3 w-[50%]"
                        placeholder="Tiktok URL"
                        id="tiktok"
                        type="text"
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Map */}
              <div
                style={{ borderRadius: "10px" }}
                className="mb-[2em] mt-[5em] w-[50%] pl-10 "
              >
                <Map coordinates={coordinates} />
              </div>
            </div>
          </form>
        </FormikProvider>
      </Card>
      {restaurant.openingHours && restaurant.openingHours.length > 0 && (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
          <div className="mb-4  w-full lg:w-[50%]">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Opening Hours
            </h1>
            <div className="flex gap-8">
              <div>
                {/* Display Working Days */}
                {restaurant.openingHours
                  .filter((dayData) => dayData.workDay)
                  .map((dayData) => (
                    <div className="flex flex-row gap-4">
                      <div className="flex w-[70%]">
                        <p className="text-[14px] text-[#777777]">
                          {dayData.day}
                        </p>
                      </div>
                      <div className="flex w-[70%]">
                        <div className="flex">
                          <p className="text-[14px] font-bold">
                            {dayData.startTime || "Closed"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-[14px] font-bold"> - </p>
                        </div>
                        <div className="flex">
                          <p className="text-[14px] font-bold">
                            {dayData.endTime || "Closed"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Display Non-Working Days */}
              <div className="ml-10">
                {restaurant.openingHours
                  .filter((dayData) => !dayData.workDay)
                  .map((dayData) => (
                    <div className="flex flex-row gap-2">
                      <div key={dayData.day} className="flex w-[60%]">
                        <p className="text-[14px] text-[#777777]">
                          {dayData.day}
                        </p>
                      </div>
                      <div key={dayData.day} className="flex gap-4">
                        <p className=" text-[14px] font-bold">Closed</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Card>
      )}
      {restaurant.avgPrice && (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
          <div className="mb-4  w-full lg:w-[50%]">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Average Price
            </h1>
            <div className="flex w-[70%] gap-2">
              <p className="text-[14px] text-[#777777]">
                Average Price for {restaurant.name} is:
              </p>
              <p className="text-[14px] font-bold">
                From {restaurant.avgPrice.from}$ to {restaurant.avgPrice.to}$
              </p>
            </div>
          </div>
        </Card>
      )}
      {galleryPhoto && galleryPhoto.length > 0 && (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
          <div className="mb-4  w-full">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Photo Gallery
            </h1>
            <div className="grid grid-cols-5 gap-4">
              {galleryPhoto.map((photo) => (
                <img
                  key={photo.id}
                  src={photo.path}
                  alt={`Photo ${photo.id}`}
                  className="h-full w-[10em] object-cover"
                />
              ))}
            </div>
          </div>
        </Card>
      )}
      {urlMenu && (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
          <div className="mb-4  w-full lg:w-[50%]">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Menu Link
            </h1>
            <div className="flex w-[70%] gap-2">
              <p className="cursor-pointer text-[14px] hover:underline">
                {urlMenu.url.map((url, index) => (
                  <a
                    key={index}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[14px]  hover:underline"
                  >
                    {url}
                  </a>
                ))}
              </p>
            </div>
          </div>
        </Card>
      )}
      {fullMenu && fullMenu.length > 0 && (
        <Card extra="w-full h-full sm:overflow-auto px-6 mb-4">
          <div className="mb-4 w-full">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Full Menu
            </h1>
            <div className="grid grid-cols-4 gap-4">
              {fullMenu.map((menu, index) => (
                <div key={menu._id} className="relative pb-4">
                  <h2 className="text-[16px] font-bold">{menu.name}</h2>
                  {index !== fullMenu.length - 1 && index % 4 !== 3 && (
                    <div className="absolute bottom-0 right-0 top-0 border-l-2"></div>
                  )}
                  <div className="flex flex-col gap-2">
                    {menu.menuItems.map((item) => (
                      <div
                        key={item._id}
                        className="mt-2 flex flex-row items-center justify-between pr-6"
                      >
                        <p className="text-[14px]">{item.name}</p>
                        <p className="text-[14px]">{item.price}$</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}
      {setMenu && setMenu.length > 0 && (
        <Card extra="w-full h-full sm:overflow-auto px-6 mb-4">
          <div className="mb-4 w-full">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Set Menu
            </h1>
            <div className="grid grid-cols-4 gap-4">
              {setMenu.map((menu, index) => (
                <div key={index} className="relative">
                  <div className="rounded-lg border border-gray-300 bg-white p-4">
                    <h2 className="mb-2 text-[16px] font-bold">{menu.name}</h2>
                    <p className="pb-20 text-[14px] text-[#777777]">
                      {menu.description}
                    </p>
                    <p className="text-[16px] font-bold text-[#7BC3E5]">
                      {menu.price}$
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}
      {photoMenu?.media && photoMenu?.media?.length > 0 && (
        <Card extra={"w-full h-full sm:overflow-auto px-6 mb-4"}>
          <div className="mb-4  w-full">
            <h1 className="pb-4 pt-6 text-xl font-bold text-navy-700 dark:text-white">
              Photo Gallery
            </h1>
            <div className="grid grid-cols-5 gap-4">
              {photoMenu?.media?.map((photo) => (
                <img
                  key={photo.id}
                  src={photo.path}
                  alt={`Photo ${photo.id}`}
                  className="h-full w-[10em] object-cover"
                />
              ))}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
