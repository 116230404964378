import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addTerms } from "api/globals/globals";
import { TermsPayload } from "interfaces/globals/globals";

export const useAddTerms = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: TermsPayload) => addTerms(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Terms were added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["terms"]);
    },
    onError: () => {
      toast({
        title: "Terms failed to be added!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
