import { FormLabel, Input, Select, Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useSignUp } from "hooks/api/useSignUp";
import { Role } from "interfaces/auth/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  dateOfBirth: Yup.date().required("Required"),
  gender: Yup.string().required("Required"),
});

export const SignUpForm: React.FC = () => {
  const { mutate, isLoading } = useSignUp();
  const navigate = useNavigate();
  const genders = ["male", "female", "other"];

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      dateOfBirth: "",
      gender: "",
      roles: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const dateOfBirthDate = new Date(values.dateOfBirth);

      dateOfBirthDate.setMinutes(
        dateOfBirthDate.getMinutes() - dateOfBirthDate.getTimezoneOffset()
      );

      const utcDateOfBirth = dateOfBirthDate.toISOString();
      mutate(
        {
          ...values,
          dateOfBirth: utcDateOfBirth,
          roles: [Role.PLACE_OWNER],
        },
        {
          onSuccess: () => {
            navigate("/auth/login");
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid =
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.gender &&
    formik.isValid &&
    formik.values.dateOfBirth &&
    formik.values.phoneNumber &&
    formik.values.password &&
    formik.values.email;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex justify-center gap-[1.5em]">
          <InputField
            {...formik.getFieldProps("firstName")}
            variant="auth"
            extra="mb-3 w-[40em]"
            label="First Name*"
            placeholder="Enter First Name"
            id="firstName"
            type="text"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("lastName")}
            variant="auth"
            extra="mb-3 w-[40em]"
            label="Last Name*"
            placeholder="Enter Last Name"
            id="lastName"
            type="text"
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex  justify-center gap-[1.5em]">
          <div className="flex flex-col">
            <FormLabel
              style={{
                fontSize: "14px",
                paddingLeft: "0.3em",
              }}
            >
              Gender*
            </FormLabel>
            <Select
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: "14em",
                "@media screen and (max-width: 940px)": {
                  width: "11em",
                },
              }}
              className="mb-3 flex  items-center justify-center  border bg-white/0  outline-none"
              {...formik.getFieldProps("gender")}
              variant="auth"
              placeholder="Enter Gender"
              id="gender"
            >
              {genders.map((gender) => (
                <>
                  <option key={gender} value={gender}>
                    {gender.charAt(0).toUpperCase() + gender.slice(1)}
                  </option>
                </>
              ))}
            </Select>
          </div>
          <div className="flex flex-col">
            <FormLabel
              style={{
                fontSize: "14px",
                paddingLeft: "0.3em",
              }}
            >
              Date of Birth*
            </FormLabel>
            <Input
              {...formik.getFieldProps("dateOfBirth")}
              value={formik.values.dateOfBirth}
              sx={{
                borderRadius: "0.75rem",
                height: "3.5em",
                fontSize: "14px",
                width: "14em",
                "@media screen and (max-width: 940px)": {
                  width: "11em",
                },
              }}
              placeholder="Select Date and Time"
              size="md"
              type="date"
            />
          </div>
        </div>
        <div className="mb-2">
          <FormLabel
            style={{
              fontSize: "14px",
              paddingLeft: "0.3em",
            }}
          >
            Phone Number*
          </FormLabel>
          <PhoneInput
            inputStyle={{
              marginTop: "0.5rem",
              display: "flex",
              height: "3rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0.75rem",
              border: "1px solid #eceaea",
              backgroundColor: "rgba(255, 255, 255, 0)",
              padding: "0.75rem",
              paddingLeft: "4em",
              fontSize: "0.875rem",
              outline: "none",
            }}
            country={"us"}
            {...formik.getFieldProps("phoneNumber")}
            value={formik.values.phoneNumber}
            placeholder="Enter Last Name"
            onChange={(value) => formik.setFieldValue("phoneNumber", value)}
          />
        </div>
        <InputField
          {...formik.getFieldProps("email")}
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="Enter Email"
          id="email"
          type="email"
          onChange={formik.handleChange}
        />
        <InputField
          {...formik.getFieldProps("password")}
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter Password"
          id="password"
          type="password"
          onChange={formik.handleChange}
        />
        <button
          className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
          ${
            !isFormInvalid
              ? "cursor-not-allowed bg-gray-400"
              : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
          }
          dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          disabled={!isFormInvalid}
        >
          {isLoading ? <Spinner /> : "Sign Up"}
        </button>
      </form>
    </FormikProvider>
  );
};
