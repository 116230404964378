import { Spinner } from "@chakra-ui/react";
import { useConfirmEmail } from "hooks/api/useConfirmEmail";

interface ConfirmEmailProps {
  code: string;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ code }) => {
  const { mutate, isLoading } = useConfirmEmail(code);

  const handleEmailConfirmation = () => {
    mutate();
  };

  return (
    <div className="mb-16 mt-[14em] flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-xl font-bold text-navy-700 dark:text-white">
          Email Verification
        </h4>
        <p className="mb-9 ml-1 justify-center text-base">
          Click the Confirm Email button to verify the email
        </p>

        <button
          onClick={handleEmailConfirmation}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {isLoading ? <Spinner /> : "Confirm Email"}
        </button>
      </div>
    </div>
  );
};

export default ConfirmEmail;
