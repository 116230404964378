import { useLogoutModal } from "contexts/AppProvider";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalContent,
} from "@chakra-ui/react";

const LogoutModal = () => {
  const { isModalOpen, closeModal } = useLogoutModal();

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Logout</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to log out?</ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="ghost">Logout</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogoutModal;
