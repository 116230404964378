import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
import { useGetLogs } from "hooks/reports/useGetLogs";
import LogsTable from "./components/LogsTable";

export default function LogsView() {
  const { data, refetch } = useGetLogs();

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin/logs") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="flex h-full w-full">
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        <div className="h-full">
          <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
            <Routes>
              {getRoutes(routes)}

              <Route
                path="/admin/logs"
                element={<Navigate to="/admin/logs" replace />}
              />
            </Routes>
          </div>
        </div>
        <LogsTable refetch={refetch} tableData={data} />
      </div>
    </div>
  );
}
