import { useMutation, useQueryClient } from "@tanstack/react-query";
import { confirmReservation } from "api/bookings/bookings";
import { RowObj } from "views/admin/bookings/components/BookingsTable";

export const useConfirmReservation = () => {
  const queryClient = useQueryClient();

  return useMutation((object: RowObj) => confirmReservation(object), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      context?.refetch();
      queryClient.invalidateQueries(["bookings"]);
    },
    onError: () => {
      //console
    },
  });
};
