import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Avatar, Flex, Table } from "@chakra-ui/react";
import { GrFormEdit } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

type RowObj = {
  _id: string;
  name: string;
  city: string;
  street: string;
  zip: string;
  state: string;
  email: string;
  phoneNumber: string;
  isVerifiedStatus: string;
  logo: {
    path: string;
  };
  edit: boolean;
};

function RestaurantTable(props: { tableData: any }) {
  const navigate = useNavigate();
  const { tableData } = props;

  const handleEditClick = (_id: string) => {
    navigate(`/admin/restaurant/add/${_id}`);
  };

  const itemsPerPage = 50;
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<
    "name" | "city" | "email" | "phoneNumber" | "isVerifiedStatus" | null
  >(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    //handle smth
  }, [props.tableData]);

  const columnNames = [
    "Restaurant Name",
    "Address",
    "Email",
    "Phone Number",
    "Status",
    "Edit",
  ];

  const handleSort = (columnName: string) => {
    const columnToSortBy: {
      [key: string]:
        | "name"
        | "city"
        | "email"
        | "phoneNumber"
        | "isVerifiedStatus"
        | null;
    } = {
      "Restaurant Name": "name",
      Address: "city",
      Email: "email",
      "Phone Number": "phoneNumber",
      Status: "isVerifiedStatus",
      Edit: null,
    };

    const sortByColumn = columnToSortBy[columnName];

    if (sortByColumn) {
      if (sortBy === sortByColumn) {
        if (sortOrder === "asc") {
          setSortOrder("desc");
        } else if (sortOrder === "desc") {
          setSortBy(null);
          setSortOrder("asc");
        }
      } else {
        setSortBy(sortByColumn);
        setSortOrder("asc");
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "PENDING":
        return "#7BC3E5";
      case "APPROVED":
        return "#83A44D";
      case "DECLINED":
        return "#C64343";
      default:
        return "";
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const sortedData = tableData
    ?.filter(
      (row: RowObj) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.phoneNumber.includes(searchTerm)
    )
    ?.sort((a: RowObj, b: RowObj) => {
      if (sortBy) {
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return 0;
      }
    });

  return (
    <>
      <div className="mb-5 flex h-[40px] items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Restaurants
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                {columnNames.map((columnName, index) => (
                  <th
                    key={index}
                    onClick={() => handleSort(columnName)}
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    <div className="items-center justify-between text-sm text-[#a3aed0]">
                      {columnName}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((row: RowObj, index: number) => {
                if (index >= startIndex && index < endIndex) {
                  return (
                    <tr key={index}>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <Flex alignItems="center">
                          {row.logo && row.logo.path ? (
                            <Avatar size="xs" src={row.logo.path} mr={2} />
                          ) : null}
                          <p className="text-black text-sm font-bold dark:text-white">
                            {row.name}
                          </p>
                        </Flex>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.street}, {row.zip}, {row.city}, {row.state}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.email ? row.email : "N/A"}
                        </p>
                      </td>

                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          +{row.phoneNumber}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p
                          style={{
                            color: getStatusColor(row.isVerifiedStatus),
                          }}
                          className="text-sm font-bold dark:text-white"
                        >
                          {row.isVerifiedStatus.charAt(0) +
                            row.isVerifiedStatus.slice(1).toLowerCase()}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3 pr-4">
                        {row.isVerifiedStatus === "APPROVED" ? (
                          <GrFormEdit
                            onClick={() => handleEditClick(row._id)}
                            className="text-black h-6 w-6 cursor-pointer font-bold dark:text-white"
                          />
                        ) : (
                          <GrFormEdit
                            className="text-black h-6 w-6 cursor-not-allowed font-bold text-gray-400"
                            style={{ pointerEvents: "none" }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default RestaurantTable;
