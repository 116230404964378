import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addOffers } from "api/offers/offers";
import { OffersPayload } from "interfaces/offers/offers";

export const useAddOffer = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((payload: OffersPayload) => addOffers(payload), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Offer was added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["offers"]);
    },
    onError: () => {
      toast({
        title: "Offer failed to be added!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
