import { Router } from "router/Router";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AppProvider } from "contexts/AppProvider";
import { ChakraProvider } from "@chakra-ui/react";
import { useState } from "react";
import {
  AuthContext,
  TokenContext,
  defaultAuthResponse,
  defaultTokenResponse,
} from "contexts/AuthContext";
import { AuthResponse } from "interfaces/auth/auth";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  const [user, setUser] = useState(defaultAuthResponse);
  const storedToken = localStorage.getItem("authToken");
  const initialToken = storedToken
    ? { access_token: storedToken, refresh_token: "" }
    : defaultTokenResponse;
  const [token, setToken] = useState<AuthResponse>(initialToken);

  return (
    <AppProvider>
      <ChakraProvider>
        <QueryClientProvider client={queryClient}>
          <TokenContext.Provider value={{ token, setToken }}>
            <AuthContext.Provider value={[user, setUser]}>
              <Router />
            </AuthContext.Provider>
          </TokenContext.Provider>
        </QueryClientProvider>
      </ChakraProvider>
    </AppProvider>
  );
};

export default App;
