import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFullMenu } from "api/restaurants/menu";
import { FullMenuPayload } from "interfaces/restaurants/menu";

export const useCreateFullMenu = (restaurantId: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FullMenuPayload) => createFullMenu(restaurantId, payload),
    {
      onSuccess: (_, variables, context: { refetch: () => void }) => {
        toast({
          title: "Full Menu was added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        context?.refetch();
        queryClient.invalidateQueries(["fullMenu"]);
      },
      onError: () => {
        toast({
          title: "Full Menu failed to create!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
