import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createSetMenu } from "api/restaurants/menu";
import { SetMenuPayload } from "interfaces/restaurants/menu";

export const useCreateSetMenu = (restaurantId: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: SetMenuPayload) => createSetMenu(restaurantId, payload),
    {
      onSuccess: (_, variables, context: { refetch: () => void }) => {
        toast({
          title: "Set Menu was added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        context?.refetch();
        queryClient.invalidateQueries(["setMenu"]);
      },
      onError: () => {
        toast({
          title: "Set Menu failed to create!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
