import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import {
  AddGlobalPayload,
  AddItemPayload,
  Choices,
  ChoicesPayload,
  Globals,
  Terms,
  TermsPayload,
} from "interfaces/globals/globals";

export const getGlobals = async () => {
  const { data } = await axiosInstance.get<Globals[]>(
    `${BaseUrl.DEVELOPMENT}/restaurant-globals`
  );

  return data;
};

export const getTerms = async () => {
  const { data } = await axiosInstance.get<Terms>(
    `${BaseUrl.DEVELOPMENT}/static-content/get-terms-condtions`
  );

  return data;
};

export const addTerms = async (payload: TermsPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/static-content/add-terms-conditions`,
    payload
  );

  return data;
};

export const getChoices = async (id: string) => {
  const { data } = await axiosInstance.get<Choices[]>(
    `${BaseUrl.DEVELOPMENT}/restaurant-globals/${id}/choices`
  );

  return data;
};

export const addChoices = async (
  restaurantId: string,
  payload: ChoicesPayload[]
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/restaurant-globals/${restaurantId}`,
    payload
  );

  return data;
};

export const addItems = async (settingId: string, payload: AddItemPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/restaurant-globals/${settingId}/items`,
    payload
  );

  return data;
};

export const addGlobal = async (payload: AddGlobalPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/restaurant-globals/globals`,
    payload
  );

  return data;
};
