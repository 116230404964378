import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { IoIosAdd } from "react-icons/io";
import { FormikProvider, useFormik } from "formik";
import { useCreateMenuItem } from "hooks/menu/useCreateMenuItem";

const AddMenuItemModal: React.FC<{ name: string; _id: string }> = ({
  name,
  _id,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate } = useCreateMenuItem();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: null,
      categoryId: String(_id),
    },
    onSubmit: (values) => {
      mutate(
        {
          categoryId: String(_id),
          ...values,
        },
        {
          onSuccess: () => {
            formik.resetForm({
              values: {
                name: "",
                description: "",
                price: "",
                categoryId: String(_id),
              },
            });
            onClose();
          },
          onError: () => {
            formik.resetForm({
              values: {
                name: "",
                description: "",
                price: "",
                categoryId: String(_id),
              },
            });
          },
        }
      );
    },
  });
  return (
    <>
      <IoIosAdd onClick={onOpen} className="h-6 w-6" />
      <Modal isOpen={isOpen} onClose={onClose}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{name}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="w-full">
                  <InputField
                    {...formik.getFieldProps("name")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Name"
                    placeholder="Enter your Item Name"
                    id="name"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("description")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Description (optional)"
                    placeholder="Enter your Item Description"
                    id="description"
                    type="text"
                    onChange={formik.handleChange}
                  />
                  <InputField
                    {...formik.getFieldProps("price")}
                    variant="auth"
                    extra="mb-3"
                    label="Item Price "
                    placeholder="Enter your Price"
                    id="price"
                    type="text"
                    onChange={formik.handleChange}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="pl-4" />
                <button className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white">
                  Add Category
                </button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormikProvider>
      </Modal>
    </>
  );
};

export default AddMenuItemModal;
