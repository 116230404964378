import { Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useToken } from "hooks/api/useToken";
import { useUpdatePassword } from "hooks/api/useUpdatePassword";
import { PasswordUpdatePayload } from "interfaces/auth/auth";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Current Password is required")
    .notOneOf(
      [Yup.ref("password")],
      "Current Password must be different from the new password"
    )
    .min(8, "Password must be at least 8 characters long"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const EditPasswordForm: React.FC = () => {
  const { mutate, isLoading } = useUpdatePassword();
  const { refetch } = useToken();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: PasswordUpdatePayload) => {
      mutate(
        {
          payload: { ...values },
        },
        {
          onSuccess: () => {
            refetch();
            formik.resetForm();
          },
          onError: () => {
            formik.resetForm();
          },
        }
      );
    },
  });

  const isFormInvalid = !formik.isValid;

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            {...formik.getFieldProps("currentPassword")}
            variant="auth"
            extra="mb-3"
            label="Current Password*"
            placeholder="Enter your Current Password"
            id="password"
            type="password"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("password")}
            variant="auth"
            extra="mb-3"
            label="New Passwrod*"
            placeholder="Enter your New Password"
            id="password"
            type="password"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("confirmPassword")}
            variant="auth"
            extra="mb-3"
            label="Confirm Password*"
            placeholder="Confirm your Password"
            id="confirmPassword"
            type="password"
            onChange={formik.handleChange}
          />
          <button
            type="submit"
            className={`mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
        ${
          isFormInvalid
            ? "cursor-not-allowed bg-gray-400"
            : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
        }
        dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            disabled={isFormInvalid}
          >
            {isLoading ? <Spinner /> : "Update Password"}
          </button>
        </form>
      </FormikProvider>
    </>
  );
};

export default EditPasswordForm;
