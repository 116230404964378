import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createUrlMenu } from "api/restaurants/menu";
import { UrlMenuPayload } from "interfaces/restaurants/menu";

export const useCreateUrlMenu = (restaurantId: string) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UrlMenuPayload) => createUrlMenu(restaurantId, payload),
    {
      onSuccess: (_, variables, context: { refetch: () => void }) => {
        toast({
          title: "URl Menu was added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        context?.refetch();
        queryClient.invalidateQueries(["menu"]);
      },
      onError: () => {
        toast({
          title: "URL Menu failed to create!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      },
    }
  );
};
