import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { useFormik, FormikProvider } from "formik";
import { useUpdateUser } from "hooks/users/useUpdateUser";
import { UpdateUsers } from "interfaces/users/users";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
});

export interface EditUserProps {
  user: UpdateUsers | null;
  opened: boolean;
  onClose: () => void;
}

const UsersModal: React.FC<EditUserProps> = ({ user, opened, onClose }) => {
  const { mutate } = useUpdateUser();
  const genders = ["male", "female", "other"];
  const status = ["Active", "Deactive"];

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      gender: user?.gender || "",
      isActive: user?.isActive || false,
      phoneNumber: user?.phoneNumber || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      mutate(
        {
          userId: String(user?._id),
          payload: { ...values },
        },
        {
          onSuccess: () => {
            handleClose();
            setSubmitting(false);
            formik.resetForm({
              values,
            });
          },
          onError: () => {
            setSubmitting(false);
            formik.resetForm({
              values,
            });
          },
        }
      );
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        gender: user?.gender || "",
        isActive: user?.isActive || false,
        phoneNumber: user?.phoneNumber || "",
      },
    });
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Modal isOpen={opened} onClose={handleClose}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Update User</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <InputField
                  {...formik.getFieldProps("firstName")}
                  variant="auth"
                  extra="mb-3"
                  label="First Name*"
                  placeholder="Enter your First Name"
                  id="firstName"
                  type="text"
                  onChange={formik.handleChange}
                />
                <InputField
                  {...formik.getFieldProps("lastName")}
                  variant="auth"
                  extra="mb-3"
                  label="Last Name*"
                  placeholder="Enter your Last Name"
                  id="lastName"
                  type="text"
                  onChange={formik.handleChange}
                />
                <FormLabel
                  style={{
                    fontSize: "14px",
                    paddingLeft: "0.3em",
                  }}
                >
                  Phone Number*
                </FormLabel>
                <PhoneInput
                  inputStyle={{
                    marginTop: "0.5rem",
                    display: "flex",
                    height: "3rem",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0.75rem",
                    border: "1px solid #eceaea",
                    backgroundColor: "rgba(255, 255, 255, 0)",
                    padding: "0.75rem",
                    paddingLeft: "4em",
                    fontSize: "0.875rem",
                    outline: "none",
                  }}
                  country={"us"}
                  {...formik.getFieldProps("phoneNumber")}
                  placeholder="Enter Last Name"
                  onChange={(value) =>
                    formik.setFieldValue("phoneNumber", value)
                  }
                />
                <FormLabel
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    paddingLeft: "0.3em",
                  }}
                >
                  Gender*
                </FormLabel>
                <Select
                  sx={{
                    borderRadius: "0.75rem",
                    height: "3.5em",
                    fontSize: "14px",
                  }}
                  className="mb-3 flex  items-center justify-center  border bg-white/0  outline-none"
                  {...formik.getFieldProps("gender")}
                  variant="auth"
                  placeholder="Enter Gender"
                  id="gender"
                >
                  {genders.map((gender) => (
                    <>
                      <option key={gender} value={gender}>
                        {gender.charAt(0).toUpperCase() + gender.slice(1)}
                      </option>
                    </>
                  ))}
                </Select>
                <FormLabel
                  style={{
                    marginTop: "10px",
                    fontSize: "14px",
                    paddingLeft: "0.3em",
                  }}
                >
                  Status*
                </FormLabel>
                <Select
                  sx={{
                    borderRadius: "0.75rem",
                    height: "3.5em",
                    fontSize: "14px",
                  }}
                  className="mb-3 flex items-center justify-center border bg-white/0 outline-none"
                  {...formik.getFieldProps("isActive")}
                  variant="auth"
                  placeholder="Select Status"
                  id="isActive"
                >
                  {status.map((s) => (
                    <option key={s} value={String(s === "Active")}>
                      {s}
                    </option>
                  ))}
                </Select>
              </ModalBody>
              <ModalFooter>
                <Button onClick={onClose} variant="ghost">
                  Close
                </Button>
                <div className="pl-4" />
                <button className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white">
                  Update User
                </button>
              </ModalFooter>
            </ModalContent>
          </form>
        </FormikProvider>
      </Modal>
    </>
  );
};

export default UsersModal;
