import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import {
  FullMenu,
  FullMenuObject,
  FullMenuPayload,
  MenuItemPayload,
  MenuItemResponse,
  PhotoMenuResponse,
  SetMenuObject,
  SetMenuPayload,
  UpdateUserObject,
  UrlMenuPayload,
  UrlMenuResponse,
} from "interfaces/restaurants/menu";
import { DeletePhotoMenuPayload } from "interfaces/restaurants/restaurants";

export const getUrlMenu = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<UrlMenuResponse>(
    `${BaseUrl.DEVELOPMENT}/url-menu/${restaurantId}`
  );

  return data;
};

export const createUrlMenu = async (
  restaurantId: string,
  payload: UrlMenuPayload
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/url-menu/${restaurantId}`,
    payload
  );

  return data;
};

export const createFullMenu = async (
  restaurantId: string,
  payload: FullMenuPayload
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/full-menu/restaurant/${restaurantId}`,
    payload
  );

  return data;
};

export const getFullMenu = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<FullMenu[]>(
    `${BaseUrl.DEVELOPMENT}/full-menu/restaurant/${restaurantId}`
  );

  return data;
};

export const updateFullMenu = async (object: FullMenuObject) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/full-menu/${object.id}`,
    object.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const deleteFullMenu = async (id: string) => {
  const { data } = await axiosInstance.delete(
    `${BaseUrl.DEVELOPMENT}/full-menu/${id}`
  );

  return data;
};

export const createMenuItem = async (payload: MenuItemPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/menu-item`,
    payload
  );

  return data;
};

export const getMenuItems = async () => {
  const { data } = await axiosInstance.get<MenuItemResponse[]>(
    `${BaseUrl.DEVELOPMENT}/menu-item`
  );

  return data;
};

export const updateMenuItem = async (object: UpdateUserObject) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/menu-item/${object.id}`,
    object.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const getMenuItemsById = async (id: string) => {
  const { data } = await axiosInstance.get<MenuItemResponse>(
    `${BaseUrl.DEVELOPMENT}/menu-item/${id}`
  );

  return data;
};

export const deleteMenuItem = async (id: string) => {
  const { data } = await axiosInstance.delete(
    `${BaseUrl.DEVELOPMENT}/full-menu/${id}`
  );

  return data;
};

export const createSetMenu = async (
  restaurantId: string,
  payload: SetMenuPayload
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/set-menu/restaurant/${restaurantId}`,
    payload
  );

  return data;
};

export const getSetMenu = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<FullMenu[]>(
    `${BaseUrl.DEVELOPMENT}/set-menu/restaurant/${restaurantId}`
  );

  return data;
};

export const updateSetMenu = async (object: SetMenuObject) => {
  const { data } = await axiosInstance.put(
    `${BaseUrl.DEVELOPMENT}/set-menu/${object.id}`,
    object.payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const uploadMenuPhotos = async (
  restaurantId: string,
  formData: FormData
) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/upload/restaurant/${restaurantId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return data;
};

export const getMenuPhotos = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<PhotoMenuResponse>(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/restaurant/${restaurantId}`
  );

  return data;
};

export const deleteMenuPhoto = async (payload: DeletePhotoMenuPayload) => {
  const { data } = await axiosInstance.delete(
    `${BaseUrl.DEVELOPMENT}/gallery-menu/restaurant/${payload.restaurantId}/media/${payload.id}`
  );

  return data;
};
