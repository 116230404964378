import { useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Table } from "@chakra-ui/react";
import { ReservationStatus } from "api/bookings/bookings";

type RowObj = {
  hasArrived: boolean;
  status: ReservationStatus;
  guestsNumber: number;
  specialRequests: string;
};

function CheckTable(props: { tableData: any; refetch: () => void }) {
  const { tableData } = props;

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Handle data updates if needed
  }, [props.tableData]);

  function getStatusDisplay(status: ReservationStatus): string {
    switch (status) {
      case ReservationStatus.INITIALLY_RESERVED:
        return "Initialy Reserved";
      case ReservationStatus.RESERVED:
        return "Reserved";
      case ReservationStatus.CANCELLED:
        return "Canceled";
      case ReservationStatus.REJECTED:
        return "Rejected";
      default:
        return "Unknown";
    }
  }

  const columnNames = ["Has Arrived", "Status", "Guests", "Special Requests"];

  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Reservations
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                {columnNames.map((columnName, index) => (
                  <th
                    key={index}
                    className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  >
                    <div className="items-center justify-between text-sm text-[#a3aed0]">
                      {columnName}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row: RowObj, index: number) => {
                if (index >= startIndex && index < endIndex) {
                  return (
                    <tr key={index}>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.hasArrived ? "Yes" : "No"}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {getStatusDisplay(row.status)}
                        </p>
                      </td>
                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.guestsNumber}
                        </p>
                      </td>

                      <td className="min-w-[150px] border-white/0 py-3  pr-4">
                        <p className="text-black text-sm font-bold dark:text-white">
                          {row.specialRequests}
                        </p>
                      </td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default CheckTable;
