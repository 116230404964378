import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOffer } from "api/offers/offers";

export const useDeleteOffer = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation((id: string) => deleteOffer(id), {
    onSuccess: (_, variables, context: { refetch: () => void }) => {
      toast({
        title: "Offer was deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      context?.refetch();
      queryClient.invalidateQueries(["offers"]);
    },
    onError: () => {
      toast({
        title: "Offer failed to be deleted!",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    },
  });
};
