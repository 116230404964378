import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
import TermsEditor from "./components/TermsEditor";
import { useGetTerms } from "hooks/terms/useGetTerms";

export default function TermsView(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { data } = useGetTerms();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === `/admin/terms-of-use`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary pt-5 dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={"Terms of use"}
              secondary={"Terms of use"}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path={`/admin/terms-of-use`}
                  element={<Navigate to={`/admin/terms-of-use`} replace />}
                />
              </Routes>
            </div>
          </div>
          <>
            <TermsEditor data={data} />
          </>
        </main>
      </div>
    </div>
  );
}
