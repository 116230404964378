import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { RowObj } from "views/admin/bookings/components/BookingsTable";

export interface FilteredRestaurantsPayload {
  limit: number;
  page: number;
  filter: {
    field?: string;
    operator?: string;
    value?: any;
  }[];
  sort: {
    field?: string;
  }[];
}

export interface Reservation {
  _id: string;
  reservationDateTime: string;
  reservedBy: ReservedBy;
  hasArrived: boolean;
  status: ReservationStatus;
  guestsNumber: number;
  specialRequests: string;
}

export enum ReservationStatus {
  INITIALLY_RESERVED = "INITIALLY_RESERVED",
  RESERVED = "RESERVED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
}

export interface ReservedBy {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface CancelObject {
  id: string;
  payload: CancelPayload;
}

interface CancelPayload {
  status: ReservationStatus;
}

export const getAllReservations = async (
  payload: FilteredRestaurantsPayload
) => {
  const { data } = await axiosInstance.post<Reservation[]>(
    `${BaseUrl.DEVELOPMENT}/reservations/filter/all`,
    payload
  );

  return data;
};

export const getOwnerReservations = async (
  payload: FilteredRestaurantsPayload,
  restaurantId: string
) => {
  const { data } = await axiosInstance.post<Reservation[]>(
    `${BaseUrl.DEVELOPMENT}/reservations/reservations/filter/restaurant/${restaurantId}`,
    payload
  );

  return data;
};

export const getAllOwnerReservations = async (
  payload: FilteredRestaurantsPayload
) => {
  const { data } = await axiosInstance.post<Reservation[]>(
    `${BaseUrl.DEVELOPMENT}/reservations/reservations/filter/restaurant`,
    payload
  );

  return data;
};

export const confirmReservation = async (object: RowObj) => {
  const { data } = await axiosInstance.patch(
    `${BaseUrl.DEVELOPMENT}/reservations/confirm/${object._id}`,
    object
  );

  return data;
};

export const cancelReservation = async (object: RowObj) => {
  const { data } = await axiosInstance.patch(
    `${BaseUrl.DEVELOPMENT}/reservations/cancel/${object._id}`,
    object
  );

  return data;
};
