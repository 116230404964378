import { axiosInstance } from "api/config";
import { BaseUrl } from "enums/BaseUrl";
import { OffersPayload } from "interfaces/offers/offers";
import { OffersResponse } from "interfaces/offers/offers";

export const getOffers = async (restaurantId: string) => {
  const { data } = await axiosInstance.get<OffersResponse[]>(
    `${BaseUrl.DEVELOPMENT}/offers/${restaurantId}`
  );

  return data;
};

export const addOffers = async (payload: OffersPayload) => {
  const { data } = await axiosInstance.post(
    `${BaseUrl.DEVELOPMENT}/offers/add-offer`,
    payload
  );

  return data;
};

export const deleteOffer = async (id: string) => {
  const { data } = await axiosInstance.delete(
    `${BaseUrl.DEVELOPMENT}/offers/${id}`
  );

  return data;
};
