import { SetStateAction, useEffect, useState } from "react";
import Card from "components/card";
import { IoChevronForwardCircleSharp } from "react-icons/io5";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { Table } from "@chakra-ui/react";
import { UpdateUsers, UsersResponse } from "interfaces/users/users";
import { GrFormEdit } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";

type RowObj = {
  firstName: string;
  lastName: string;
  username: string;
  gender: string;
  phoneNumber: string;
  isActive: boolean;
};

function UsersTable(props: {
  tableData: any;
  user?: UsersResponse;
  onEditUser: (user: UpdateUsers) => SetStateAction<UpdateUsers> | void;
  refetch: () => void;
}) {
  const { tableData, onEditUser } = props;

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<keyof RowObj | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = tableData.filter((row: RowObj) =>
    Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedData = sortColumn
    ? filteredData.sort((a: RowObj, b: RowObj) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (sortDirection === "asc") {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else if (sortDirection === "desc") {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        } else {
          return 0;
        }
      })
    : filteredData;

  const handleSort = (column: keyof RowObj) => {
    if (sortColumn === column) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === "asc"
          ? "desc"
          : prevSortDirection === "desc"
          ? null
          : "asc"
      );
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Handle data updates if needed
  }, [props.tableData]);

  return (
    <>
      <div className="mb-5 flex h-[40px]  items-center rounded-full border-[1px] border-gray-200 bg-[white] text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
          className="block h-full w-full rounded-full bg-[white] text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit xl:w-[200px]"
        />
      </div>
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Clients
          </div>
        </header>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Table className="w-full">
            <thead>
              <tr className="!border-px !border-gray-400">
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("firstName")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    First Name
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("lastName")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Last Name
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("username")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Email
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("gender")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Gender
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("phoneNumber")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Phone Number
                  </div>
                </th>
                <th
                  className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                  onClick={() => handleSort("isActive")}
                >
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Status
                  </div>
                </th>
                <th className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start">
                  <div className="items-center justify-between text-sm text-[#a3aed0]">
                    Edit
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedData
                .slice(startIndex, endIndex)
                .map((row: RowObj, index: number) => (
                  <tr key={index}>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.firstName}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.lastName}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.username}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        {row.gender &&
                          row.gender.charAt(0).toUpperCase() +
                            row.gender.slice(1).toLowerCase()}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p className="text-black text-sm font-bold dark:text-white">
                        +{row.phoneNumber}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <p
                        className={`text-sm font-bold ${
                          row.isActive ? "text-green-500" : "text-red-500"
                        } dark:text-white`}
                      >
                        {row.isActive ? "Active" : "Deactive"}
                      </p>
                    </td>
                    <td className="min-w-[150px] border-white/0 py-3  pr-4">
                      <GrFormEdit
                        onClick={() => onEditUser(row)}
                        className="text-black h-6 w-6 cursor-pointer font-bold dark:text-white"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="mt-4 flex justify-end pb-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronBackCircleSharp
                className={`h-6 w-6 ${
                  currentPage === 1 ? "text-gray-700" : "text-brand-500"
                }`}
              />
            </button>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`"bg-white text-brand-500" }  rounded-md px-4
              py-2`}
            >
              <IoChevronForwardCircleSharp
                className={`h-6 w-6 ${
                  currentPage === totalPages
                    ? "text-gray-700"
                    : "text-brand-500"
                }`}
              />
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}

export default UsersTable;
