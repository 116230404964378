import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React, { useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import routes from "routes";
import SetMenu from "./components/SetMenu";
import { useGetSetMenu } from "hooks/menu/useGetSetMenu";
import Loader from "components/loader/Loader";
import SetMenuModal from "./components/modals/AddSetMenuModal";
import EditSetMenuModal from "./components/modals/EditSetMenuModal";
import { UpdateMenuItems } from "interfaces/restaurants/menu";

export default function SetMenuView(props: { [x: string]: any }) {
  const { _id } = useParams();
  const { data, isLoading } = useGetSetMenu(String(_id));
  const { ...rest } = props;
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const [selectedSetMenu, setSelectedMenu] = useState<{
    setMenu: UpdateMenuItems | null;
    open: boolean;
  }>({
    setMenu: null,
    open: false,
  });

  const handleEditSetMenu = (setMenu: UpdateMenuItems) => {
    setSelectedMenu({
      setMenu: setMenu,
      open: true,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === `/admin/restaurant/set-menu/${_id}`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={"Set Menu"}
              secondary={"Set Menu"}
              onClick={back}
              icon={"<"}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[4vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path={`/admin/restaurant/set-menu/${_id}`}
                  element={
                    <Navigate
                      to={`/admin/restaurant/set-menu/${_id}`}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
          </div>
          {data && data.length > 0 ? (
            <>
              <SetMenu onEditSetMenu={handleEditSetMenu} data={data} />
            </>
          ) : isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col items-center justify-center pt-[15em]">
              <p>There are no categories, click the button to add.</p>
              <SetMenuModal />
            </div>
          )}
          <EditSetMenuModal
            setMenu={selectedSetMenu.setMenu}
            opened={selectedSetMenu.open}
            onClose={() => {
              setSelectedMenu({
                setMenu: null,
                open: false,
              });
            }}
          />
        </main>
      </div>
    </div>
  );
}
