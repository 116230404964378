import Card from "components/card";
import LineChart from "components/charts/LineChart";
import { useGetUsersPerMonthReports } from "hooks/reports/useGetUsersPerMonthReports";

const TotalSpent = () => {
  const { data } = useGetUsersPerMonthReports();

  if (!data) {
    return <p>Loading...</p>;
  }

  const filteredData = data.filter(
    (item) => item.month !== null && item.year !== null
  );

  filteredData.sort((a, b) => a.month - b.month);

  const chartData = filteredData.map((item) => item.count);

  const months = filteredData.map((item) => {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return monthNames[item.month - 1];
  });

  const chartUsers = [
    {
      name: "Users",
      data: chartData,
      color: "#4318FF",
    },
  ];

  const chartUsersOptions = {
    legend: {
      show: false,
    },

    theme: {
      mode: "light",
    },
    chart: {
      type: "line",

      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: "",
        backgroundColor: "#000000",
      },
      theme: "dark",
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      type: "text",
      range: "",
      categories: months,
    },

    yaxis: {
      show: false,
    },
  };

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex justify-between">
        <p className="mt-[20px] text-xl font-bold text-navy-700 dark:text-white">
          Users per month
        </p>
      </div>

      <div className="flex h-full w-full flex-row justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart chartOptions={chartUsersOptions} chartData={chartUsers} />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
