import {
  Box,
  Checkbox,
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useAddChoices } from "hooks/globals/useAddChoices";
import { useGetChoices } from "hooks/globals/useGetChoices";
import { SelectedOptions } from "interfaces/globals/globals";
import { Globals } from "interfaces/globals/globals";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface CustomizeDetailsProps {
  data: Globals[];
}

export const CustomizeDetails: React.FC<CustomizeDetailsProps> = ({ data }) => {
  const { _id } = useParams();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { data: choices } = useGetChoices(String(_id));
  const [selectedChoices, setSelectedChoices] = useState([]);
  const { mutate } = useAddChoices(String(_id));

  useEffect(() => {
    const initializeSelectedChoices = () => {
      if (choices && choices.length === 0 && data && data.length > 0) {
        const initialChoices = data.map((global) => ({
          settingsId: global._id,
          selectedOptions: [],
        }));
        setSelectedChoices(initialChoices);
      } else if (choices && choices.length > 0) {
        setSelectedChoices(choices);
      }
    };

    initializeSelectedChoices();
  }, [choices, data, _id]);

  const handleCheckboxChange = (settingItemId: string, settingsId: string) => {
    setSelectedChoices((prevChoices) => {
      let updated = false;
      const newChoices = prevChoices.map((choice) => {
        if (choice.settingsId === settingsId) {
          updated = true;
          const isSelected = choice.selectedOptions.some(
            (option: SelectedOptions) => option._id === settingItemId
          );
          const selectedOptions = isSelected
            ? choice.selectedOptions.filter(
                (option: SelectedOptions) => option._id !== settingItemId
              )
            : [
                ...choice.selectedOptions,
                { _id: settingItemId, name: "", desc: "" },
              ];
          return { ...choice, selectedOptions };
        }
        return choice;
      });
      if (!updated) {
        newChoices.push({
          settingsId: settingsId,
          selectedOptions: [{ _id: settingItemId, name: "", desc: "" }],
        });
      }

      return newChoices;
    });
  };

  const handleSaveChanges = () => {
    const payload = selectedChoices.filter(
      (choice) => choice.selectedOptions.length > 0
    );
    mutate(payload);
  };

  return (
    <>
      <Tabs
        variant="unstyled"
        index={activeTab}
        onChange={(index) => setActiveTab(index)}
      >
        <TabList>
          {data.map((item, index) => (
            <Tab
              key={index}
              bg={"white"}
              borderTopRightRadius={"10px"}
              borderTopLeftRadius={"10px"}
              _selected={{
                color: "white",
                bg: "#7BC3E5",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              {item.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {data.map((global, index) => (
            <div
              key={global._id}
              className={`flex flex-col rounded-[10px] rounded-t-none bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none`}
            >
              {activeTab === index && (
                <Grid className="p-8" templateColumns="repeat(4, 1fr)" gap={4}>
                  {global.settingsItems.map((settingItem) => (
                    <GridItem key={settingItem._id}>
                      <Box gap={4} display="flex" alignItems="center">
                        <Checkbox
                          borderRadius={12}
                          colorScheme="blue"
                          isChecked={selectedChoices.some(
                            (choice) =>
                              choice.selectedOptions &&
                              choice.selectedOptions.some(
                                (option: SelectedOptions) =>
                                  option._id === settingItem._id
                              )
                          )}
                          size="lg"
                          onChange={() =>
                            handleCheckboxChange(settingItem._id, global._id)
                          }
                        />
                        <p>{settingItem.name}</p>
                      </Box>
                    </GridItem>
                  ))}
                </Grid>
              )}
            </div>
          ))}
        </TabPanels>
      </Tabs>
      <div className="flex justify-end">
        <button
          onClick={handleSaveChanges}
          className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Save Changes
        </button>
      </div>
    </>
  );
};
