import { FormLabel, Select, Spinner } from "@chakra-ui/react";
import InputField from "components/fields/InputField";
import { AuthContext } from "contexts/AuthContext";
import { FormikProvider, useFormik } from "formik";
import { useToken } from "hooks/api/useToken";
import { useUpdateProfile } from "hooks/api/useUpdateProfile";
import { useContext } from "react";
import PhoneInput from "react-phone-input-2";

const EditProfileForm: React.FC = () => {
  const [user] = useContext(AuthContext);
  const { mutate, isLoading } = useUpdateProfile();
  const { refetch } = useToken();
  const genders = ["male", "female", "other"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      gender: user?.gender,
    },
    onSubmit: (values) => {
      mutate(
        {
          payload: { ...values },
        },
        {
          onSuccess: () => {
            refetch();
          },
        }
      );
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            {...formik.getFieldProps("firstName")}
            variant="auth"
            extra="mb-3"
            label="First Name*"
            placeholder="Enter your First Name"
            id="firstName"
            type="text"
            onChange={formik.handleChange}
          />
          <InputField
            {...formik.getFieldProps("lastName")}
            variant="auth"
            extra="mb-3"
            label="Last Name*"
            placeholder="Enter your Last Name"
            id="lastName"
            type="text"
            onChange={formik.handleChange}
          />
          <FormLabel
            style={{
              fontSize: "14px",
              paddingLeft: "0.3em",
            }}
          >
            Gender*
          </FormLabel>
          <Select
            sx={{
              borderRadius: "0.75rem",
              height: "3.5em",
              fontSize: "14px",
            }}
            className="mb-3 flex  items-center justify-center  border bg-white/0  outline-none"
            {...formik.getFieldProps("gender")}
            variant="auth"
            id="gender"
          >
            {genders.map((gender) => (
              <>
                <option key={gender} value={gender}>
                  {gender.charAt(0).toUpperCase() + gender.slice(1)}
                </option>
              </>
            ))}
          </Select>
          <FormLabel
            style={{
              fontSize: "14px",
              paddingLeft: "0.3em",
            }}
          >
            Phone Number*
          </FormLabel>
          <PhoneInput
            inputStyle={{
              marginTop: "0.5rem",
              display: "flex",
              height: "3rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0.75rem",
              border: "1px solid #eceaea",
              backgroundColor: "rgba(255, 255, 255, 0)",
              padding: "0.75rem",
              paddingLeft: "4em",
              fontSize: "0.875rem",
              outline: "none",
            }}
            country={"us"}
            {...formik.getFieldProps("phoneNumber")}
            value={formik.values.phoneNumber}
            placeholder="Enter Last Name"
            onChange={(value) => formik.setFieldValue("phoneNumber", value)}
          />
          <button
            type="submit"
            className={`mt-6 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200 
        ${
          !formik.dirty
            ? "cursor-not-allowed bg-gray-400"
            : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700"
        }
        dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            disabled={!formik.dirty}
          >
            {isLoading ? <Spinner /> : "Update Profile"}
          </button>
        </form>
      </FormikProvider>
    </>
  );
};

export default EditProfileForm;
