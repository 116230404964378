import Card from "components/card";
import InputField from "components/fields/InputField";
import { FormikProvider, useFormik } from "formik";
import { useCreateUrlMenu } from "hooks/menu/useCreateUrlMenu";
import { UrlMenuResponse } from "interfaces/restaurants/menu";
import { useParams } from "react-router-dom";

interface UrlMenuProps {
  data: UrlMenuResponse;
}

export const UrlMenu: React.FC<UrlMenuProps> = ({ data }) => {
  const { _id } = useParams();
  const { mutate } = useCreateUrlMenu(String(_id));

  const formik = useFormik({
    initialValues: {
      url: Array.isArray(data?.url) ? data?.url[0] : data?.url,
    },
    onSubmit: (values) => {
      mutate(
        {
          ...values,
        },
        {
          onError: () => {
            formik.resetForm({
              values,
            });
          },
        }
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Card
          extra={`w-full h-full sm:overflow-auto px-6 mb-4 cursor-pointer `}
        >
          <header
            className={`relative flex cursor-pointer flex-row items-center justify-between pt-4`}
          >
            <div className="w-full">
              <InputField
                {...formik.getFieldProps("url")}
                variant="auth"
                extra="mb-3"
                label="Enter menu link"
                placeholder="Enter your URL Menu"
                id="url"
                type="text"
                onChange={formik.handleChange}
              />
            </div>
          </header>
        </Card>
        <div className="flex justify-end">
          <button
            className="mt-2 w-[8em] rounded-xl bg-brand-500 py-[12px] text-sm font-medium text-white 
          transition duration-200 hover:bg-brand-600
         active:bg-brand-700
         dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save Changes
          </button>
        </div>
      </form>
    </FormikProvider>
  );
};
